const Cases = () =>
  import(
    /* webpackChunkName: "Cases" */
    "@/components/amos/Cases"
  );

const Visits = () =>
  import(
    /* webpackChunkName: "Visits" */
    "@/components/amos/Visits"
  );

const Referencials = () =>
  import(
    /* webpackChunkName: "Referencials" */ "@/components/amos/Referencials"
  );

const Management = () =>
  import(/* webpackChunkName: "Management" */ "@/components/amos/Management");

const Admin = () =>
  import(/* webpackChunkName: "Admin" */ "@/components/amos/Admin");

const MyProfile = () =>
  import(/* webpackChunkName: "MyProfile" */ "@/components/amos/MyProfile");

import { showErrorNotification } from "@/composables/useNotifications";
import store from "@/store";
import VisitsChildren from "./visits/visits-children";
import ReferencialsChildren from "./referencials/referencials-children.js";
import AdminChildren from "./admin/admin-children.js";
import ManagementChildren from "./management/management-children.js";

const privilegedAccess = async (to, from, next) => {
  if (store.getters["user/getCurrentUser"].isPrivileged()) {
    next();
  } else {
    await showErrorNotification("Access denied");
    next({ name: "cases" });
  }
};

export default [
  {
    path: "",
    name: "home",
    // INFO - M.S - 03/01/2022
    // This redirection for fix the active class that is always active on "/"
    redirect: { name: "cases" },
  },
  {
    path: "cases",
    name: "cases",
    component: Cases,
  },
  {
    path: "visits",
    name: "projects",
    component: Visits,
    children: VisitsChildren,
  },
  {
    path: "referencials",
    component: Referencials,
    children: ReferencialsChildren,
    beforeEnter: privilegedAccess,
  },
  {
    path: "admin",
    component: Admin,
    children: AdminChildren,
    beforeEnter: privilegedAccess,
  },
  {
    path: "management",
    component: Management,
    children: ManagementChildren,
    beforeEnter: privilegedAccess,
  },
  {
    path: "profile",
    name: "edit-profile", // INFO - MS - route name comes from SocioMainNavbarUserMenu (socio-vue-components)
    component: MyProfile,
  },
];
