import { Model } from "@vuex-orm/core";

export default class CachedProject extends Model {
  static entity = "cachedProjects";
  static primaryKey = "project";

  static fields() {
    return {
      createdAt: this.string("", () => new Date().toISOString()),
      project: this.string(""),
      user: this.string(""),
      status: this.string(""),
      progress: this.number(0),
    };
  }
}
