import { BaseModel } from "./baseModels";

export default class ReportConfigGraphic extends BaseModel {
  static name = "ReportConfigGraphic";
  static entity = "reportConfigGraphic";

  static fields() {
    return {
      ...super.fields(),
      graphicCode: this.string(""),
      label: this.string(""),
      submissionList: this.string(""),
    };
  }
}
