import { BaseModel } from "./baseModels";

export class Relation extends BaseModel {
  static entity = "relation";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(null),
      appId: this.attr(""),
      appServiceId: this.attr(""),
      appEntityName: this.attr(""),
      appEntityId: this.attr(""),
      targetService: this.attr(""),
      targetEntityName: this.attr(""),
      linkType: this.attr(""),
      targetEntityId: this.attr(""),
      isArchived: this.boolean(false),
      createdBy: this.attr(""),
      updatedBy: this.attr(""),
    };
  }
}
