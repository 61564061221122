import { BaseAdminGroupModel } from "./baseModels";

export default class DifferabilityGroup extends BaseAdminGroupModel {
  static name = "DifferabilityGroup";

  static entity = "differabilityGroups";

  static fields() {
    return {
      ...super.fields(),
      differabilities: this.attr([]),
    };
  }

  get itemsList() {
    return this.differabilities;
  }
}
