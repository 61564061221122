const ManagementHomepage = () =>
  import(
    /* webpackChunkName: "ManagementHomepage" */ "@/components/amos/management/ManagementHomepage"
  );

export default [
  {
    path: "",
    name: "management",
    component: ManagementHomepage,
  },
];
