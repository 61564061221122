import { Model } from "@vuex-orm/core";

export default class QuotationGroupQuotationPivot extends Model {
  static name = "QuotationGroupQuotationPivot";
  static entity = "quotationGroupQuotationPivots";

  static primaryKey = ["quotationGroupUuid", "quotationUuid"];

  static fields() {
    return {
      quotationGroupUuid: this.attr(null),
      quotationUuid: this.attr(null),
    };
  }
}
