import { BaseAdminModel } from "./BaseAdminModel";

export default class RiskAssessment extends BaseAdminModel {
  static name = "RiskAssessment";

  static entity = "riskAssessments";

  static fields() {
    return {
      ...super.fields(),
      colorCode: this.attr(""),
      weightingCoefficient: this.attr(""),
      riskRating: this.attr(""),
    };
  }
}
