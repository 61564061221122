import { BaseModel } from "./baseModels";

export default class QuotationAnalyticalAxis extends BaseModel {
  static name = "QuotationAnalyticalAxis";

  static entity = "quotationAnalyticalAxes";

  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      analyticalAxis: this.attr(),
      quotation: this.attr(),
      description: this.attr(),
      ordering: this.attr(0),
      isArchived: this.attr(false),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      descriptionFr: this.string(""),
      descriptionEnGb: this.string(""),
    };
  }
}
