import { BaseAdminModel } from "./BaseAdminModel";

export default class ModelVersion extends BaseAdminModel {
  static name = "ModelVersion";
  static entity = "modelVersions";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      updatedAt: this.string(""),
      createdAt: this.string(""),
      isArchived: this.boolean(false),
      model: this.string(),
      version: this.number(),
      fetchedAt: this.string(),
    };
  }
}
