import { Model } from "@vuex-orm/core";

export default class BuildingCharacteristic extends Model {
  static name = "BuildingCharacteristic";
  static entity = "buildingCharacteristic";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.string(""),
      constructionList: this.attr([]),
      surfaceList: this.attr([]),
      labelCertificationList: this.attr([]),
      isResidential: this.boolean(false),
      individualResidentialFamily: this.string(""),
      collectifResidentialFamilyList: this.attr([]),
      isErp: this.boolean(false),
      erpCategory: this.string(""),
      erpMainType: this.string(""),
      erpSecondaryTypeList: this.attr([]),
      isErt: this.boolean(false),
      ertBottomFloorLastLevel: this.string(""),
      isIgh: this.boolean(false),
      ighTypeList: this.attr([]),
      classificationOrigin: this.string(""),
      historicalClassification: this.string(""),
      historicalPerimeter: this.string(""),
      legalAssemblyList: this.attr([]),
      legalAssemblySource: this.string(""),
      totalPerson: this.number(0),
      totalPersonSource: this.string(""),
      publicPerson: this.number(0),
      publicPersonSource: this.string(""),
      staffPerson: this.number(0),
      staffPersonSource: this.string(""),
      description: this.string(""),
      co2Emissions: this.number(0),
      performanceNotation: this.string(""),
      gasEmissionsNotation: this.string(""),
      residentialLot: this.number(0),
      notResidentialLot: this.number(0),
      totalLot: this.number(0),
      mainActivity: this.string(""),
      mainActivityOther: this.string(""),
      secondaryActivityList: this.attr([]),
      secondaryActivityOther: this.string(""),
      occupancyStatus: this.string(""),
      occupancyStatusOther: this.string(""),
      parkingTotal: this.number(0),
      parking4Wheels: this.number(0),
      fluidEnergyDateFormat: this.string(""),
      primaryConsumption: this.number(0),
      finalConsumption: this.number(0),
      fluidEnergyDate: this.string(""),
      fluidEnergySource: this.string(""),
      theoricalWorkforceList: this.attr([]),
      clearanceList: this.attr([]),
      isIhm: this.boolean(false),
      designation: this.string(""),
      carriedTrack: this.string(""),
      obstacleCrossedTrack: this.string(""),
      visitHistoryList: this.attr([]),
      provisional: this.boolean(false),
      mobile: this.boolean(false),
      transportAxis: this.string(""),
      referencePointStart: this.string(""),
      referencePointEnd: this.string(""),
      strategicRating: this.number(1),
      isParkingArea: this.boolean(false),
      isIcpe: this.boolean(false),
    };
  }
}
