import { BaseModel } from "./baseModels";

export default class ObservationHistory extends BaseModel {
  static entity = "observationHistory";

  static fields() {
    return {
      ...super.fields(),
      observation: this.string(""),
      reportName: this.string(""),
      reportDate: this.string(""),
      historyDate: this.string(""),
      documentUuid: this.string(""),
      modifiedBy: this.string(""),
      report: this.string(""),
    };
  }
}
