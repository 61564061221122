import { useRxdbCollection } from "@/composables/useRxdbCollection";
import { schemas } from "@/rxdb/schemas.js";
import { createDatabase } from "@/rxdb/init.js";

let initialized = false;
let instance = null;

const SERVER_SYNC_STATE = "last-server-sync";
const SYNC_ERROR_STATE = "sync-error-state";

const RxStates = {
  [SERVER_SYNC_STATE]: null,
  [SYNC_ERROR_STATE]: null,
};

const subscribeFromState = (rxStateName, key) => {
  if (!RxStates[rxStateName]) {
    throw new Error(`${rxStateName} does not exist in local database`);
  }
  try {
    return RxStates[rxStateName].get$(`${key}`);
  } catch (e) {
    console.warn(`${key} in serverSyncState failed`, e);
    return "";
  }
};

const readFromState = (rxStateName, key) => {
  if (!RxStates[rxStateName]) {
    throw new Error(`${rxStateName} does not exist in local database`);
  }
  try {
    return RxStates[rxStateName].get(`${key}`);
  } catch (e) {
    console.warn(`${key} in serverSyncState failed`, e);
    return "";
  }
};
const writeToState = async (rxStateName, key, setterCallBack) => {
  if (!RxStates[rxStateName]) {
    RxStates[rxStateName] = await instance.addState(rxStateName);
  }
  await RxStates[rxStateName].set(`${key}`, setterCallBack);
};
export default class RxDB {
  /**
   * @typedef {import("rxdb").RxDatabase} RxDatabase
   * @returns {RxDatabase}
   */
  static getInstance() {
    return instance;
  }

  static readServerSyncState = (key) => {
    return readFromState(SERVER_SYNC_STATE, `${SERVER_SYNC_STATE}.${key}`);
  };

  static writeServerSyncState = async (key, setterCallBack) => {
    await writeToState(
      SERVER_SYNC_STATE,
      `${SERVER_SYNC_STATE}.${key}`,
      setterCallBack
    );
  };
  static readSyncErrorState = (key) =>
    readFromState(SYNC_ERROR_STATE, `${SYNC_ERROR_STATE}.${key}`);
  static subscribeFromErrorState = (key) =>
    subscribeFromState(SYNC_ERROR_STATE, `${SYNC_ERROR_STATE}.${key}`);

  static writeSyncErrorState = async (key, setterCallBack) =>
    await writeToState(
      SYNC_ERROR_STATE,
      `${SYNC_ERROR_STATE}.${key}`,
      setterCallBack
    );

  static async initializeRxDB() {
    if (initialized) {
      return;
    }
    instance = await createDatabase(schemas);

    if (!RxStates[SERVER_SYNC_STATE])
      RxStates[SERVER_SYNC_STATE] = await instance.addState(SERVER_SYNC_STATE);
    if (!RxStates[SYNC_ERROR_STATE]) {
      RxStates[SYNC_ERROR_STATE] = await instance.addState(SYNC_ERROR_STATE);
    }
    initialized = true;
  }
}

export const modelMustFetch = async (
  modelName,
  cachedModelVersion,
  collectionName
) => {
  const { execOnCollection: modelColleciton } =
    useRxdbCollection(collectionName);
  const { execOnCollection: modelVersionCollection } =
    useRxdbCollection("model_version");
  const documents = await modelColleciton((c) => c.find().exec());

  if (!documents.length) return true;

  const selector = { model: modelName.toLowerCase() };
  let fetchedModelVersion = await modelVersionCollection((c) =>
    c.find({ selector }).exec()
  );

  fetchedModelVersion = fetchedModelVersion.length
    ? fetchedModelVersion[0]
    : null;

  if (!fetchedModelVersion && !cachedModelVersion) return true;

  const lastFetchedAt = new Date(cachedModelVersion.fetchedAt);
  const differenceInHours =
    (new Date().getTime() - lastFetchedAt.getTime()) / 3600000;

  return (
    fetchedModelVersion?.version > cachedModelVersion?.version ||
    differenceInHours > 24
  );
};

export const makeSchemaCompliantIgnoringKeys = (data, keyToIgnore) => {
  if (Array.isArray(data)) {
    for (const item of data) {
      for (const key of keyToIgnore) {
        delete item[key];
      }
    }
  } else {
    for (const key of keyToIgnore) {
      delete data[key];
    }
  }
  return data;
};

export const makeSchemaCompliantKeepingKeys = (
  data,
  keyToKeep,
  keyToCast = {}
) => {
  // INFO - PH - 23/10/2024 - We cannot insert value in rxdb with Observer.
  data = JSON.parse(JSON.stringify(data));

  if (Array.isArray(data)) {
    const formatedData = [];

    for (const item of data) {
      formatedData.push(makeObjectComplient(item, keyToKeep, keyToCast));
    }

    return formatedData;
  }

  return makeObjectComplient(data, keyToKeep, keyToCast);
};

const makeObjectComplient = (data, keyToKeep, keyToCast = {}) => {
  for (const key in keyToCast) {
    data[key] = keyToCast[key](data[key]);
  }

  const serializedData = Object.fromEntries(
    Object.entries(data).filter(([key]) => keyToKeep.includes(key))
  );

  return serializedData;
};

export const convertMapToArray = (map) => {
  if (map instanceof Map) {
    return Array.from(map.values());
  }
  return map;
};

// note G.P : dont abuse this code.
// it s hack for when a collection is not inited properly due to concurency issues.
// it should be use when refactoring the syteme is too costly, but you should first consider other options
export const waitForCollection = async (collectionList) => {
  while (!RxDB.getInstance())
    await new Promise((resolve) => setTimeout(resolve, 500));
  for (const collection of collectionList) {
    let safeCount = 0;
    let collectionInited = Object.keys(RxDB.getInstance().collections).includes(
      collection
    );

    while (!collectionInited && safeCount < 50) {
      await new Promise((resolve) => setTimeout(resolve, 300));
      collectionInited = Object.keys(RxDB.getInstance().collections).includes(
        collection
      );

      safeCount += 1;
    }
    if (!collectionInited) {
      throw new Error("Rxdb not init correctly");
    }
  }
};

// Utils to load attachment rxdb from a collection

export const loadDocumentAttachmentBlob = async (documentUuid) => {
  if (!documentUuid) return null;

  try {
    const { execOnCollection: documentCollection } =
      useRxdbCollection("document");

    const document = await documentCollection((c) =>
      c.findOne(documentUuid).exec()
    );

    if (!document) {
      console.warn(`Document with UUID ${documentUuid} not found`);
      return null;
    }

    const attachment = document.getAttachment(document.thumbnail);
    if (!attachment) {
      console.warn(`Attachment for document UUID ${documentUuid} not found`);
      return null;
    }

    const blob = await attachment.getData();
    return blob;
  } catch (error) {
    console.error(
      `Failed to load attachment for document UUID ${documentUuid}`,
      error
    );
    return null;
  }
};
