import { BaseAdminModel } from "./BaseAdminModel";

export default class ActionType extends BaseAdminModel {
  static name = "ActionType";

  static entity = "actionTypes";

  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      uniqueCode: this.attr(""),
    };
  }
}
