import { Model } from "@vuex-orm/core";
import { ReferencialNode } from "@socotec.io/socio-vue-components";

export class RapsotecNodeAmos extends Model {
  static name = "RapsotecNodeAmos";
  static entity = "rapsotecNodeAmos";
  static primaryKey = "uuid";

  static state() {
    return {
      rapsotecNodeMap: {},
    };
  }

  static fields() {
    return {
      uuid: this.uid(""),
      path: this.attr(""),
      level: this.attr(1),
      numchild: this.attr(0),
      parentNode: this.attr(null),
      code: this.attr(null),
      label: this.attr(""),
      description: this.attr(""),
      expertComment: this.attr(null),
      missionCodesList: this.attr([]),
      destinationCodesList: this.attr([]),
      typeCodesList: this.attr([]),
      isArchived: this.attr(false),
      annexe: this.attr(false),
      modifiedByUuid: this.attr(null),
      modifiedByUser: this.attr(null),
      conditionalTitles: this.attr([]),
      conditionalDisplay: this.attr([]),
      updatedAt: this.attr(),
      regulationDocumentsList: this.attr([]),
      referencialNode: this.belongsTo(ReferencialNode, "referencialNode"),
    };
  }

  static beforeCreate(model) {
    this.commit((state) => {
      state.rapsotecNodeMap[model.uuid] = Object.freeze(model);
    });
  }
}
