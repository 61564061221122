import { BaseAssetModel } from "./baseAssetModel";
import BuildingAsset from "./BuildingAsset";

export class BaseAssetWithBuildingModel extends BaseAssetModel {
  static fields() {
    return {
      building: this.string(""), // foreign key
      buildingData: this.belongsTo(BuildingAsset, "building"),
      ...super.fields(),
    };
  }
}
