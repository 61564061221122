import { BaseModel } from "./baseModels";

export default class QuotationValue extends BaseModel {
  static name = "QuotationValue";

  static entity = "quotationValues";

  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      value: this.attr(0, (value) => (value === -1 ? null : value)),
      isArchived: this.attr(false),
    };
  }
}
