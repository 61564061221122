import { Case as BaseCase } from "@socotec.io/socio-vue-components";

export default class Case extends BaseCase {
  static name = "Case";
  static entity = "cases";
  static fields() {
    return {
      ...super.fields(),
      caseMainRecipient: this.attr(null),
    };
  }
}
