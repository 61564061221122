import AosObservable from "./AosObservable";
import { BaseModel } from "./baseModels";
import Observation from "./Observation";

export default class SubObservation extends BaseModel {
  static name = "SubObservation";

  static fields() {
    return {
      ...super.fields(),
      // foreign keys
      observation: this.string(""),

      // aggregates
      actionsCount: this.number(0),
      smallestQuotationValue: this.number(-1),
      analyticalAxis: this.string(""),
      aosObservablesList: this.attr([]),
      referencialNodeUuid: this.string(""),
      aosItems: this.attr([]),

      perimeter: this.attr(null),
    };
  }

  makePerimeter() {
    const observation = Observation.find(this.observation);
    return observation.makePerimeter();
  }

  get relatedAosItems() {
    return AosObservable.findIn(this.aosObservablesList).map(
      (pObs) => pObs.aosItemData
    );
  }
}
