let worker = null;

export default class RxDBWorker {
  /**
   *
   * @returns {Worker}
   */
  static getInstance() {
    return worker;
  }

  static initWorker() {
    if (window.Worker) {
      worker = new Worker(
        new URL("../rxdbWorker/rxdb-worker.js", import.meta.url)
      );
    }
  }
}
