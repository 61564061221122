import { BaseAdminGroupModel } from "./baseModels";

export default class QuotationGroup extends BaseAdminGroupModel {
  static name = "QuotationGroup";

  static entity = "quotationGroups";

  static fields() {
    return {
      ...super.fields(),
      quotationsList: this.attr([]),
      groupTypesList: this.attr([]),
    };
  }
}
