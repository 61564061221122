import { BaseAdminModel } from "./BaseAdminModel";

export default class ActionStatus extends BaseAdminModel {
  static name = "ActionStatus";

  static entity = "actionStatuses";

  static fields() {
    return {
      ...super.fields(),
      shortLabel: this.attr(""),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      shortLabelFr: this.string(""),
      shortLabelEnGb: this.string(""),
    };
  }
}
