import { BaseModel } from "./baseModels";

export default class ExpenseOverTime extends BaseModel {
  static name = "ExpenseOverTime";
  static entity = "expensesOverTime";

  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      amount: this.attr(""),
      startDate: this.attr(""),
      isSplit: this.attr(false),
      isSelected: this.attr(false),
      isSelectable: this.attr(false),

      // Foreign keys
      action: this.attr(),
      scenario: this.attr(),
    };
  }

  static deleteAndUpdate = (expensesOverTime, newExpensesOverTime) => {
    expensesOverTime = Array.isArray(expensesOverTime)
      ? expensesOverTime
      : [expensesOverTime];
    const uuids = expensesOverTime.map((expense) => expense.uuid);
    this.delete((expense) => uuids.includes(expense.uuid));
    this.insert({
      data: newExpensesOverTime,
    });
  };
}
