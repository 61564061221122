import AnalyticalAxis from "./AnalyticalAxis";
import { BaseModel } from "./baseModels";
import ProjectConfig from "./ProjectConfig";
import QuotationGroup from "./QuotationGroup";

export default class ProjectConfigAnalyticalAxis extends BaseModel {
  static name = "ProjectConfigAnalyticalAxis";
  static entity = "projectConfigAnalyticalAxes";

  static fields() {
    return {
      ...super.fields(),
      analyticalAxisData: this.belongsTo(AnalyticalAxis, "analyticalAxis"),
      projectConfigData: this.belongsTo(ProjectConfig, "projectConfig"),
      quotationGroupData: this.belongsTo(QuotationGroup, "quotationGroup"),
      isDefaultAnalyticalAxisEditable: this.attr(),
      isOthersAnalyticalAxesEditable: this.attr(),
      isDefaultQuotationGroupEditable: this.attr(),

      // Foreign keys
      analyticalAxis: this.string(""),
      projectConfig: this.string(""),
      quotationGroup: this.string(""),
    };
  }
}
