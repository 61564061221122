import { BaseAdminModel } from "./BaseAdminModel";

export default class Priority extends BaseAdminModel {
  static name = "Priority";

  static entity = "priorities";
  static listAction = "amosAdmin/fetchPriorities";

  static fields() {
    return {
      ...super.fields(),
      value: this.attr(0),
    };
  }
}
