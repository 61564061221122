const VisitGed = () =>
  import(
    /* webpackChunkName: "VisitGed" */ "@/components/amos/visits/visit/ged/VisitGed"
  );
import { amosRootPermissions } from "@/utils/amosPermissions";

export default [
  {
    path: "",
    name: "project-ged",
    component: VisitGed,
    meta: { permissions: amosRootPermissions.ged },
  },
];
