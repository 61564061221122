import { camelToSnakeCase } from "@/utils/helpers";
import { Relation } from "@vuex-orm/core";
import { isEqual } from "lodash";
import serialize from "serialize-javascript";
// This file handles logic related to generating json validation schema used by rxdb to create collections.
// because we are too lazy to write and maintain shemas, version and migration, we automated it.
// - generation of a json schema from a vuexOrm model
// - identifying diff between a current version of a schema and  new one and generating migration strategies

/*
// generate Json "properties" value for a given model's attribute
//
// @param schema {Object} : json validation schema to populate. Modified by reference.
// @param modelName {String} : name of vuexOrm model. used for Debug
// @param fieldName {string} : key of the vuexOrm Model to add in json validation schema
// @param field {Object} : VuexOrm model field for given fieldName
// @param supportedType {String[]} : list of natives types json schema accepts
//
// @return void
*/
const addFieldProperties = (
  schema,
  modelName,
  fieldName,
  field,
  isVuexModel,
  supportedTypes = ["string", "number", "boolean", "array"]
) => {
  // by default everything is string
  let entry = { type: "string" };
  let type;

  if (!isVuexModel) {
    type = field.type;
  } else type = field.constructor.name.split("$")[0].toLowerCase();

  // if type is directly defined in the vuexOrm or in the JsonValidationSchema, use it
  if (type && supportedTypes.includes(type)) {
    entry = { type };
    if (type === "array") {
      entry.items = { type: field.subType };
    }
  } // else try to guess
  else if (supportedTypes.includes(typeof field.value)) {
    entry = { type: typeof field.value };
  } else if (Array.isArray(field.value)) {
    entry = {
      type: "array",
      items: { type: "string" },
    };
  } else if (field.value instanceof Object) {
    entry = { type: "object" };
  }

  schema.properties[fieldName] = entry;

  // if the field happens to be an index for given model
  // https://rxdb.info/rx-schema.html#indexes
  if (schema.indexes?.flat().includes(fieldName)) {
    if (["integer", "number"].includes(schema.properties[fieldName].type)) {
      // number fields that are used in an index, must have set minimum, maximum and multipleOf
      Object.assign(schema.properties[fieldName], {
        minimum: -1000000,
        maximum: 1000000,
        multipleOf: 0.001,
      });
    } else if (schema.properties[fieldName].type === "string") {
      // maxLength is a required property for string indexes
      schema.properties[fieldName].maxLength = 200;
    }
  }
};

const isCompositePrimaryKey = (primaryKey) => {
  return Array.isArray(primaryKey);
};

const setPrimaryKey = (primaryKey) => {
  if (!isCompositePrimaryKey(primaryKey)) return primaryKey;
  return {
    key: "id",
    fields: primaryKey,
    separator: "|",
  };
};

// Check if we are working with a vuexORM class model or a JsonSchema class
const isCustomModel = (model) => {
  return (
    model.___class___type &&
    typeof model.___class___type === "function" &&
    model.___class___type() === "JsonValidationSchema"
  );
};
/*
// generate schema for a single model
//
// @param model {object} vuexOrm model to generate as schema
// @param forceFieldItems see generateRxDBSchemaForAllModels
// @param forcedFieldTypes see generateRxDBSchemaForAllModels
//
// @return {object} json validation shema 
*/

const generateRxDBSchema = (model, forcedFieldItems, forcedFieldTypes) => {
  let primaryKey = model.primaryKey;
  const modelAttributeObj = model.fields();
  const modelIndexes = model.rxdbIndexes;

  // Primary Key
  const schema = {
    title: `${model.entity} schema`,
    version: 0,
    primaryKey: setPrimaryKey(primaryKey),
    type: "object",
    properties: {},
    required: [],
    definitions: {},
  };

  // https://rxdb.info/rx-schema.html#composite-primary-key
  if (isCompositePrimaryKey(primaryKey)) {
    schema.required.push("id");
    schema.properties.id = { type: "string" };
    primaryKey = "id";
  }

  // Indexes
  if (modelIndexes) {
    if (!Array.isArray(modelIndexes)) {
      console.error("rxdbIndexes must be an array");
    } else if (modelIndexes.length) {
      schema.indexes = modelIndexes;
    }
  }
  if (model.rxdbAttachments) {
    schema.attachments = {};
  }

  // Set up field properties
  const isVuexModel = !isCustomModel(model);
  if (!modelAttributeObj) return;
  for (const fieldName of Object.keys(modelAttributeObj)) {
    // skip every relation field of VuexORM model
    if (
      fieldName in schema.properties ||
      modelAttributeObj[fieldName] instanceof Relation
    )
      continue;

    addFieldProperties(
      schema,
      model.name,
      fieldName,
      modelAttributeObj[fieldName],
      isVuexModel
    );
  }

  schema.properties[primaryKey].maxLength = 100;

  // apply forced type
  Object.keys(forcedFieldTypes).forEach((key) => {
    if (schema.properties[key] && primaryKey !== key) {
      schema.properties[key].type = forcedFieldTypes[key];
    }
  });
  Object.keys(forcedFieldItems).forEach((key) => {
    if (schema.properties[key] && primaryKey !== key) {
      schema.properties[key].items = forcedFieldItems[key];
    }
  });
  return schema;
};

const getModelStatics = () => {
  const statics = {
    getFields: function () {
      return Object.keys(this.schema.jsonSchema.properties).filter(
        (field) => !field.startsWith("_")
      );
    },
  };

  return statics;
};

/*
// generate a json validation schema from given parameters
//
// @param selectedModels {vuexOrmModel[]} list of model to generate as schema
// @param forcedFieldItems {Object} forced given list of attribute to be of {string[]} in the schema
// @param forcedFieldType {Object of KEY: VALUE} force model attribute of KEY to be of type VALUE
//
// @return {Object} json validation schema for all models
*/
export const generateRxDBSchemaForAllModels = (
  selectedModels,
  forcedFieldItems,
  forcedFieldTypes
) => {
  const rxdbSchemas = {};

  selectedModels.forEach((model) => {
    const rxdbSchema = generateRxDBSchema(
      model,
      forcedFieldItems,
      forcedFieldTypes
    );
    if (rxdbSchema) {
      const collectionName = camelToSnakeCase(model.name);
      const statics = getModelStatics();

      rxdbSchemas[collectionName] = { schema: rxdbSchema, statics };
    } else {
      console.warn(`rxdbSchema for model ${model.name} failed to generate`);
    }
  });
  return rxdbSchemas;
};

export const getSchemasDelta = (currentSchemas, newSchemas) => {
  const getKeysDifference = (baseKeys, compareKeys) =>
    baseKeys.filter((key) => !compareKeys.includes(key));

  const delta = { added: {}, removed: [], modified: {} };

  let currentKeys = Object.keys(currentSchemas);
  let newKeys = Object.keys(newSchemas);

  // track removed collection
  delta.removed = getKeysDifference(currentKeys, newKeys);

  // track added collection
  const addedKeyList = getKeysDifference(newKeys, currentKeys);
  addedKeyList.forEach((newKey) => {
    delta.added[newKey] = newSchemas[newKey];
  });

  // track differences for collection modified beetwen 2 versions
  for (const [newKey, newSchema] of Object.entries(newSchemas)) {
    if (!(newKey in currentSchemas)) continue; // if old schema did not have the colleciton, skip
    const currentProperties = currentSchemas[newKey].schema.properties;
    const newProperties = newSchema.schema.properties;

    const currentPropKeys = Object.keys(currentProperties);
    const newPropKeys = Object.keys(newProperties);

    const removedKeyList = getKeysDifference(currentPropKeys, newPropKeys);
    const addedKeyList = getKeysDifference(newPropKeys, currentPropKeys);

    const modifiedCollection = {
      added: {},
      removed: removedKeyList,
      modified: {},
    };

    addedKeyList.forEach((newPropKey) => {
      modifiedCollection.added[newPropKey] = newProperties[newPropKey];
    });

    currentPropKeys.forEach((propKey) => {
      if (
        propKey in newProperties &&
        !isEqual(currentProperties[propKey], newProperties[propKey])
      ) {
        modifiedCollection.modified[propKey] = {
          removed: currentProperties[propKey],
          added: newProperties[propKey],
        };
      }
    });
    if (
      Object.keys(modifiedCollection.added).length ||
      modifiedCollection.removed.length ||
      Object.keys(modifiedCollection.modified).length
    ) {
      delta.modified[newKey] = modifiedCollection;
    }
  }

  Object.keys(delta.modified).forEach((collection) => {
    if (
      isEqual(delta.modified[collection], {
        added: {},
        removed: [],
        modified: {},
      })
    ) {
      delete delta.modified[collection];
    }
  });
  return delta;
};

const getMigrationStrategy = (property, oldProperty, newProperty) => {
  if (!newProperty) return `delete oldDoc.${property}`;

  if (
    oldProperty &&
    oldProperty.type === "array" &&
    newProperty.type !== "array"
  ) {
    return "";
  }

  if (newProperty.type === "number")
    return `oldDoc.${property} = oldDoc.${property} === undefined ? oldDoc.${property} : parseFloat(oldDoc.${property})`;
  if (newProperty.type === "boolean")
    return `oldDoc.${property} = oldDoc.${property} === undefined ? oldDoc.${property} : (oldDoc.${property} === "true")`;
  if (newProperty.type === "string")
    return `oldDoc.${property} = oldDoc.${property} === undefined ? oldDoc.${property} : oldDoc.${property}.toString()`;

  return "";
};

const createMigrationFn = (migrationsStrategy) => {
  const functionBody = `${migrationsStrategy}        return oldDoc;`;
  return new Function("oldDoc", functionBody);
};

const applyMigration = (schema, migrationFunction, baseVersion) => {
  const version = baseVersion + 1;
  schema.schema.version = version;

  if (!schema.migrationStrategies) {
    schema.migrationStrategies = {};
  }

  if (!schema.migrationStrategies[version]) {
    schema.migrationStrategies[version] = migrationFunction;
  }
};

export const getSchemasWithMigrationStrategies = (
  currentSchemas,
  newSchemas,
  delta
) => {
  // copy migration strategies from current schemas to new schemas
  for (const collection in newSchemas) {
    if (!(collection in currentSchemas)) continue;

    newSchemas[collection].schema.version =
      currentSchemas[collection].schema.version;

    if (currentSchemas[collection].migrationStrategies) {
      newSchemas[collection].migrationStrategies =
        currentSchemas[collection].migrationStrategies;
    }
  }

  if (delta.modified && Object.keys(delta.modified).length) {
    Object.keys(delta.modified).forEach((collection) => {
      const migrationStrategies = getMigrationStrategy(
        delta.modified[collection],
        currentSchemas,
        newSchemas
      );
      const migrationFunction = createMigrationFn(migrationStrategies);
      applyMigration(
        newSchemas[collection],
        migrationFunction,
        currentSchemas[collection].schema.version
      );
    });
  }
  return serialize(newSchemas, { space: 2 });
};
