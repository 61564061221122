import { Pin } from "@socotec.io/socio-vue-components";

export default class BIMDataPin extends Pin {
  static name = "BIMDataPin";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      submission: this.string(""),
    };
  }
}
