import ActionType from "./ActionType";
import Ventilation from "./Ventilation";
import Periodicity from "./Periodicity";
import Priority from "./Priority";
import ExpenseOverTime from "./ExpenseOverTime";
import { Company } from "@socotec.io/socio-vue-components";
import Contributor from "@/models/Contributor";
import Differability from "./Differability";
import ROI from "./ROI";
import Projection from "./Projection";
import CostScale from "./CostScale";
import SourcePricing from "./SourcePricing";
import { BaseModel } from "./baseModels";
import Observation from "./Observation";
import AosObservable from "./AosObservable";
import store from "@/store";
import { makePerimeterFromAosObservables } from "@/utils/perimeterRepr";

export default class Action extends BaseModel {
  static name = "Action";

  static entity = "actions";

  static fields() {
    return {
      ...super.fields(),
      label: this.attr(""),
      date: this.attr(),
      dateFormat: this.attr(),
      lifespanExtension: this.number(0),
      isSuggested: this.boolean(false),
      description: this.attr(""),
      totalAmount: this.number(0),
      quantity: this.attr(0),
      actionTypeData: this.belongsTo(ActionType, "actionRef"),
      priorityData: this.belongsTo(Priority, "priority"),
      periodicityData: this.belongsTo(Periodicity, "periodicity"),
      ventilationData: this.belongsTo(Ventilation, "ventilation"),
      differabilityData: this.belongsTo(Differability, "differability"),
      roiData: this.belongsTo(ROI, "ROI"),
      projectionData: this.belongsTo(Projection, "projection"),
      costScaleData: this.belongsTo(CostScale, "costScale"),
      sourcePricingData: this.belongsTo(SourcePricing, "sourcePricing"),
      expensesOverTimeList: this.hasMany(ExpenseOverTime, "action"),
      isArchived: this.boolean(false),
      importantFieldsUpdatedAt: this.attr(""),
      isInSentReport: this.boolean(false),

      companies: this.attr([]),
      contributors: this.attr([]),

      // foreign keys
      previousRelatedAction: this.string(""),
      actionRef: this.string(""),
      priority: this.string(""),
      periodicity: this.attr(null),
      status: this.attr(""),
      differability: this.attr(""),
      ventilation: this.string(""),
      roi: this.attr(""),
      projection: this.attr(""),
      costScale: this.attr(""),
      sourcePricing: this.attr(""),
      observationsList: this.attr([]),

      createdBy: this.string(""),
      modifiedBy: this.string(""),
      createdByEmail: this.string(""),
      modifiedByEmail: this.string(""),
      createdByFirstname: this.string(""),
      modifiedByFirstname: this.string(""),
      createdByLastname: this.string(""),
      modifiedByLastname: this.string(""),

      isInherited: this.boolean(false),

      analyticalAxesList: this.attr([]),

      // aggregates
      periodicityString: this.string(""),
    };
  }
  static async fetchContributorsFromList(contributorsList) {
    const getContribId = (cont) => cont.contributorUuid;
    const contribsUuids = contributorsList
      .filter((c) => !c.isCompany)
      .map(getContribId);
    const companiesUuids = contributorsList
      .filter((c) => c.isCompany)
      .map(getContribId);

    await Promise.all([
      store.dispatch("contributor/fetchContributors", {
        filters: { uuids: contribsUuids },
      }),
      store.dispatch("company/fetchCompanies", {
        filters: { uuids: companiesUuids },
      }),
    ]);

    return {
      contributors: store.getters["contributor/getProjectContributors"],
      companies: store.getters["company/getCompaniesByUuids"](companiesUuids),
    };
  }

  async fetchContributors() {
    return Action.fetchContributorsFromList(this.contributorsList);
  }

  getLabeledContributors() {
    return this.contributorsList.map(({ contributorUuid, isCompany }) => ({
      label: isCompany
        ? Company.find(contributorUuid)?.title
        : Contributor.find(contributorUuid)?.userEmail,
      contributorUuid,
      isCompany,
    }));
  }

  get observableUuids() {
    const observations = Observation.findIn(this.observationsList);
    return [...new Set(observations.flatMap((obs) => obs.aosObservablesList))];
  }

  get relatedAosItems() {
    return AosObservable.findIn(this.observableUuids).map(
      (pObs) => pObs.aosItemData
    );
  }

  get relatedObservableItems() {
    return AosObservable.findIn(this.observableUuids);
  }

  static deleteArchivedActions = () => {
    this.delete((action) => action.isArchived);
  };

  makePerimeter() {
    const { assets, pathsMapping } =
      this.$store().getters["asset/getAllAssets"];
    return makePerimeterFromAosObservables({
      aosObservablesList: this.observableUuids,
      assets,
      pathsMapping,
    });
  }
}
