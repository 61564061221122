import { BaseAdminGroupModel } from "./baseModels";

export default class PriorityGroup extends BaseAdminGroupModel {
  static name = "PriorityGroup";
  static entity = "priorityGroups";

  static fields() {
    return {
      ...super.fields(),
      reportLabel: this.attr(),
      priorities: this.attr([]),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      reportLabelFr: this.string(""),
      reportLabelEnGb: this.string(""),
    };
  }

  get itemsList() {
    return this.priorities;
  }
}
