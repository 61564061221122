import {
  booleanValidation,
  JsonValidationSchema,
  stringListValidation,
  stringValidation,
} from "@/rxdb/class/JsonValidationSchema";

export default class ObjectTypeStatement extends JsonValidationSchema {
  static primaryKey = "uuid";
  static entity = "objectTypeStatements";

  @stringValidation
  uuid = "";

  @stringValidation
  createdAt = "";

  @stringValidation
  updatedAt = "";

  @stringValidation
  contentType = "";

  @booleanValidation
  isApplicableToChildren = false;

  @stringValidation
  ordering = 0;

  @booleanValidation
  isArchived = false;

  @stringValidation
  statementType = "";

  @stringValidation
  quotationValue = "";

  @stringValidation
  analyticalAxis = "";

  @stringValidation
  objectId = "";

  @stringValidation
  analyticalAxisCode = "";

  @stringListValidation
  actionTypes = [];
}
