import { amosRootPermissions } from "@/utils/amosPermissions";

const ReportGeneration = () =>
  import(
    /* webpackChunkName: "ReportsGeneration" */ "@/components/amos/visits/visit/reports/ReportsGeneration"
  );

const VisitReport = () =>
  import(
    /* webpackChunkName: "VisitReport" */ "@/components/amos/visits/visit/reports/VisitReport"
  );

export default [
  {
    path: "generation/:templateId/:standByReportId/:step/",
    props: true,
    name: "report-generation",
    component: ReportGeneration,
    meta: {
      permissions: amosRootPermissions["report-generation"],
    },
  },
  {
    path: "",
    name: "project-reports",
    component: VisitReport,
  },
];
