import { BaseAdminGroupModel } from "./baseModels";

export default class ROIGroup extends BaseAdminGroupModel {
  static name = "ROIGroup";

  static entity = "roiGroups";

  static fields() {
    return {
      ...super.fields(),
      rOIs: this.attr([]),
    };
  }

  get itemsList() {
    return this.rOIs;
  }
}
