import store from "@/store";
import { showErrorNotification } from "@/composables/useNotifications";
import { APP_NAMES, MISSION_CODES_TO_APP } from "@/utils/const";
import { ProjectConfig } from "@/models";
import ProjectConfigAnalyticalAxis from "../../models/ProjectConfigAnalyticalAxis";
import { Project } from "@socotec.io/socio-vue-components";
import { useRxdbCollection } from "../../composables/useRxdbCollection.js";
import { convertMapToArray } from "@/rxdb/utils.js";
const isAllowed = async (projectUuid) => {
  let currentUser = store.getters["user/getCurrentUser"];
  if (!currentUser.isAllowedRead(projectUuid)) {
    throw new Error("You are not allowed to access this project");
  }
};

const commitProjectInfos = (project) => {
  const appName = MISSION_CODES_TO_APP[project.mission];
  const currentApp = APP_NAMES[appName] ?? APP_NAMES.AMOS_REAL_ESTATE;
  store.commit("project/SET_CURRENT_PROJECT", project.uuid);
  store.commit("project/SET_CURRENT_APP", currentApp);
};

const initializeProject = async (projectUuid) => {
  const { project } = await store.dispatch("project/retrieveProjectData", {
    projectUuid,
  });
  commitProjectInfos(project);
  return project;
};

const initializeOfflineProject = async (projectUuid) => {
  if (!projectUuid)
    throw Error("No project uuid provided to initializeOfflineProject");
  const { execOnCollection: execOnProject } = useRxdbCollection("project");
  const projectRxDocument = await execOnProject((c) =>
    c
      .findOne({
        selector: {
          uuid: projectUuid, // bcause project is not indexed by uuid
        },
      })
      .exec()
  );
  const project = projectRxDocument?.toJSON();

  if (!project) throw Error(`Project ${projectUuid} not found locally`);

  const { execOnCollection: execOnProjectConfig } =
    useRxdbCollection("project_config");

  const projectConfig = await execOnProjectConfig((c) =>
    c.findOne({ selector: { project: projectUuid } }).exec()
  );

  await Project.insert({
    data: project,
  });
  await ProjectConfig.insert({
    data: projectConfig.toJSON(),
  });

  const { execOnCollection: execOnProjectCAA } = useRxdbCollection(
    "project_config_analytical_axis"
  );
  const projectConfigAnalyticalAxisMap = await execOnProjectCAA((c) =>
    c.findByIds(projectConfig.analyticalAxisUuid).exec()
  );
  const projectConfigAnalyticalAxis = convertMapToArray(
    projectConfigAnalyticalAxisMap
  );
  await ProjectConfigAnalyticalAxis.create({
    data: projectConfigAnalyticalAxis.map((e) => e.toJSON()),
  });
  store.commit("project/SET_IS_PICASSO_VISIT", project.mission === "HAIA");
  commitProjectInfos(project);
};

export default async (to, from, next) => {
  store.commit("SET_APP_IS_LOADING", true);
  const { projectUuid } = to.params;
  await isAllowed(projectUuid);

  if (!store.state.appIsOnline) {
    await initializeOfflineProject(projectUuid);
    next();
  } else {
    try {
      const project = await initializeProject(projectUuid);
      const isProjectConfigured =
        !!project.projectConfigData?.submissions.length;

      if (isProjectConfigured || to.name === "project-config") {
        next();
      } else {
        next({ name: "project-config", params: to.params });
      }
    } catch (err) {
      console.error(err);
      await showErrorNotification("Access denied");
      if (!from.name) next({ name: "cases" });
      return;
    }
  }
  store.commit("SET_APP_IS_LOADING", false);
};
