import { BaseModel } from "./baseModels";

export default class AnalyticalAxis extends BaseModel {
  static name = "AnalyticalAxis";

  static entity = "analyticalAxes";

  static state() {
    return {
      codeToUuidMap: {},
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      label: this.string(""),
      shortLabel: this.attr(""),
      isArchived: this.attr(false),
      code: this.string(""),
      ordering: this.number(0),
      description: this.attr(""),
      missionObject: this.attr(""),
      missionObjective: this.attr(""),
      referenceText: this.attr(""),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      labelFr: this.string(""),
      labelEnGb: this.string(""),
      shortLabelFr: this.string(""),
      shortLabelEnGb: this.string(""),
      descriptionFr: this.string(""),
      descriptionEnGb: this.string(""),
      missionObjectFr: this.string(""),
      missionObjectEnGb: this.string(""),
      missionObjectiveFr: this.string(""),
      missionObjectiveEnGb: this.string(""),
      referenceTextFr: this.string(""),
      referenceTextEnGb: this.string(""),
    };
  }

  static afterCreate(model) {
    this.commit((state) => {
      state.codeToUuidMap[model.code] = model.uuid;
    });
  }
}
