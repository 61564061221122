import { BaseModel } from "./baseModels";

export class BaseAdminModel extends BaseModel {
  static state() {
    return {
      isInserted: false, // flag to check if the model is inserted in the store after rxdb-worker end
    };
  }

  static fields() {
    return {
      ...super.fields(),
      code: this.string(""),
      label: this.string(""),

      ordering: this.attr(),
      description: this.attr(""),
      isArchived: this.boolean(false),

      // i18n fields. Normally you should never have to read these fields directly.
      // They are only here to generate rxdb schemas.
      labelFr: this.string(""),
      labelEnGb: this.string(""),
      descriptionFr: this.string(""),
      descriptionEnGb: this.string(""),
    };
  }
}
