import { BaseAdminModel } from "./BaseAdminModel";

export default class CostScale extends BaseAdminModel {
  static name = "CostScale";
  static entity = "costScales";
  static listAction = "amosAdmin/fetchCostScales";

  static fields() {
    return {
      ...super.fields(),
      uniqueCode: this.attr(),
    };
  }
}
