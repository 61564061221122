import { Model } from "@vuex-orm/core";
import { TASK_STATUS } from "@/utils/const";

export default class Task extends Model {
  static name = "Task";
  static primaryKey = "uuid";
  static entity = "tasks";

  static fields() {
    return {
      uuid: this.attr(""),
      createdAt: this.attr(""),
      status: this.attr(""),
      progress: this.number(0),
      result: this.attr({}),
      message: this.attr(""),
      action: this.attr(undefined),
      model: this.attr(""),
    };
  }

  get isSuccessOrFailure() {
    return [TASK_STATUS.SUCCESS, TASK_STATUS.FAILURE].includes(this.status);
  }
}
