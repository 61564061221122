import { BaseMissionModel } from "./baseModels";

export default class Mission extends BaseMissionModel {
  static name = "Mission";
  static entity = "missions";

  static fields() {
    return {
      ...super.fields(),
      sectionCode: this.attr(null),
      order: this.attr(999),
      isRelatedMission: this.boolean(false),
    };
  }
}
