import { BaseAdminModel } from "./BaseAdminModel";
import QuotationValue from "./QuotationValue";

export default class Quotation extends BaseAdminModel {
  static name = "Quotation";

  static entity = "quotations";

  static fields() {
    return {
      ...super.fields(),
      quotationValue: this.attr(null),
      quotationValueData: this.belongsTo(QuotationValue, "quotationValue"),
    };
  }
}
