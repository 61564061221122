import IdentificationChildren from "./identification/identification-children";

const Identification = () =>
  import(
    /* webpackChunkName: "Selection" */
    "@/components/amos/visits/visit/aos-item-routes/Identification"
  );

export default [
  {
    path: "identification",
    children: IdentificationChildren,
    component: Identification,
  },
];
