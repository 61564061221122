import { BaseMissionModel } from "./baseModels";
import Mission from "./Mission";
import { AmosComponentType } from "@/models";

export default class SubMission extends BaseMissionModel {
  static name = "SubMission";
  static entity = "subMissions";

  static fields() {
    return {
      ...super.fields(),
      mission: this.uid(),
      missionData: this.belongsTo(Mission, "mission"),
      defaultExtraConfig: this.attr({}),
      editableExtraConfigRule: this.attr({}),
      reportTemplateTypesList: this.attr([]),
      componentTypesList: this.attr([]),
      componentTypesData: this.hasManyBy(
        AmosComponentType,
        "componentTypesList"
      ),

      // Picasso
      rduCode: this.string(""),
    };
  }
}
