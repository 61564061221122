import { Project as BaseProject } from "@socotec.io/socio-vue-components";
import ProjectConfig from "./ProjectConfig";

export default class Project extends BaseProject {
  static name = "Project";
  static entity = "projects";
  static fields() {
    return {
      ...super.fields(),
      projectConfig: this.attr(null),
      projectConfigData: this.belongsTo(ProjectConfig, "projectConfig"),
      projectPilot: this.attr(null),
      managerFirstName: this.string(""),
      managerLastName: this.string(""),
      perimeter: this.string(""),
      createdAt: this.attr(""),
    };
  }
  static async createFromListerData(data) {
    await super.createFromListerData(data);
    return this.update({
      chronorapso: data.projectChronorapso,
      projectPilot: data.projectPilot,
      mission: data.projectOfferItemCodesList[0],
    });
  }
}
