import { Contributor as BaseContributor } from "@socotec.io/socio-vue-components";
import ActorTypology from "./ActorTypology";

export default class Contributor extends BaseContributor {
  static name = "Contributor";

  static fields() {
    return {
      ...super.fields(),
      actorTypologies: this.hasManyBy(ActorTypology, "actorTypologiesIds"),
      actorTypologiesIds: this.attr([]),
    };
  }
}
