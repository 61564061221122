const ChooseReferencial = () =>
  import(
    /* webpackChunkName: "ChooseReferencial" */ "@/components/amos/referencials/ChooseReferencial"
  );
const DispositionReferencial = () =>
  import(
    /* webpackChunkName: "DispositionReferencial" */ "@/components/amos/referencials/DispositionReferencial"
  );

const AosComponentsReferencial = () =>
  import(
    /* webpackChunkName: "AosComponentsReferencial" */ "@/components/amos/referencials/AosComponentsReferencial"
  );

const DocReviewReferencial = () =>
  import(
    /* webpackChunkName: "DocReviewReferencial" */ "@/components/amos/referencials/DocReviewReferencial"
  );

const TechnicalInspectionReferencial = () =>
  import(
    /* webpackChunkName: "TechnicalInspectionReferencial" */ "@/components/amos/referencials/TechnicalInspectionReferencial"
  );

export default [
  {
    path: "",
    name: "referencials",
    component: ChooseReferencial,
  },
  {
    path: "disposition-referencial",
    name: "amos-disposition",
    component: DispositionReferencial,
  },
  {
    path: "aos-component-classification",
    name: "aos-component-classification",
    component: AosComponentsReferencial,
  },
  {
    path: "amos-doc-review",
    name: "amos-doc-review",
    component: DocReviewReferencial,
  },
  {
    path: "rapsotec-ct",
    name: "rapsotec-ct",
    component: TechnicalInspectionReferencial,
  },
];
