import { ANALYTICAL_AXIS_CODES } from "@/utils/const";
import { BaseReferencialModel } from "./baseModels";

export default class TypologyTypeNode extends BaseReferencialModel {
  static name = "TypologyTypeNode";
  static primaryKey = "uuid";
  static entity = "typologyTypeNodes";

  static fields() {
    return {
      ...super.fields(),
      // foreign keys
      typologyType: this.string(""),
      analyticalAxis: this.string(""),

      // aggregates
      referencialVersion: this.string(""),
      regulatory: this.string(""),
      applicationCondition: this.string(""),
      apcriClassification: this.string(""),
      surveyRule: this.string(""),
    };
  }

  static beforeCreate(model) {
    const entities = this.store().state.entities;

    const typologyType = entities.typologyTypes.referencialUuidMap[model.uuid];
    if (!typologyType) {
      this.commit((state) => {
        state.notFoundItems.add(model.path);
      });
    }

    const docReviewAxis =
      entities.analyticalAxes.codeToUuidMap[ANALYTICAL_AXIS_CODES.DOC_REVIEW];
    if (!docReviewAxis) {
      console.error(
        `Could not find analytical axis (Document Review) for typology node, data might not be loaded correctly`
      );
    }

    model.analyticalAxis = docReviewAxis;
    model.typologyType = typologyType?.uuid;
    model.referencialVersion = typologyType?.referencialVersion;
    model.regulatory = typologyType?.regulatory;
    model.applicationCondition = typologyType?.applicationCondition;
    model.apcriClassification = typologyType?.apcriClassificationLabel;
    model.surveyRule = typologyType?.surveyRuleLabel;
  }
}
