import { BaseModel } from "./baseModels";
export class TagThrough extends BaseModel {
  static relatedModel = () => null;

  static fields() {
    return {
      ...super.fields(),
      tag: this.attr(null), // fk
      tagData: this.belongsTo(AssetTag, "tag"),
      item: this.attr(null), // fk
      itemData: this.belongsTo(this.relatedModel(), "item"),
      createdBy: this.attr(""), // usermanagement_uuid
    };
  }

  flatten() {
    if (!this.itemData) {
      this.tagData = AssetTag.find(this.tag);
    }
    return {
      uuidThrough: this.uuid,
      createdBy: this.createdBy,
      ...this.tagData,
      closable: this.closable,
    };
  }
  get closable() {
    const user = this.$store().getters["user/getCurrentUser"];
    if (user.isAllowed()) return true;
    return user.usermanagementUuid === this.createdBy;
  }
}
export default class AssetTag extends BaseModel {
  static name = "AssetTag";
  static entity = "assetTags";

  static fields() {
    return {
      ...super.fields(),
      label: this.attr(""),
      editable: this.boolean(true),
      parent: this.attr(null), // fk
    };
  }
}
