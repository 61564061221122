import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

  wb.addEventListener("activated", (event) => {
    if (!event.isUpdate) {
      console.log("[workbox] Service worker activated for the first time.");
    } else {
      console.log("[workbox] Updated service worker activated.");
    }
  });

  wb.addEventListener("waiting", () => {
    console.warn(
      "[workbox] The service worker has installed but is waiting for existing clients to close before activating..."
    );
  });

  wb.addEventListener("installing", (event) => {
    console.log("[workbox] Installing a service-worker...", event.sw);
  });

  wb.addEventListener("redundant", (event) => {
    console.log("[workbox] redundant service-worker...", event.sw);
  });

  wb.addEventListener("installed", (event) => {
    if (!event.isUpdate) {
      console.log("[workbox] Registered service worker installed.");
    } else {
      console.log("[workbox] Updated service worker found. Installing now...");
    }
  });

  wb.addEventListener("controlling", () => {
    console.log(
      "[workbox] A service worker with the same script URL is already controlling this page."
    );
    window.location.reload();
  });

  wb.register()
    .then((registration) => {
      console.log(
        "[workbox] Successfully registered service worker.",
        registration
      );
    })
    .catch((error) => {
      console.error("[workbox] Service worker registration failed:", error);
    });
} else {
  wb = null;
}

export default wb;
