import { BaseModel } from "./baseModels";

export default class YearlyBudget extends BaseModel {
  static name = "YearlyBudget";
  static entity = "yearlyBudgets";

  static fields() {
    return {
      ...super.fields(),
      amount: this.attr(""),
      date: this.attr(""),

      // Foreign keys
      scenario: this.attr(),
    };
  }
  static deleteAndUpdate = (yearlyBudget, newYearlyBudget) => {
    this.delete(yearlyBudget.uuid);
    this.insert({
      data: newYearlyBudget,
    });
  };
}
