import SubObservation from "./SubObservation";

export default class CtNodeObservation extends SubObservation {
  static name = "CtNodeObservation";
  static entity = "ctNodeObservations";

  static fields() {
    return {
      ...super.fields(),

      // foreign keys
      ctNode: this.string(""),
    };
  }
}
