import { Model } from "@vuex-orm/core";

export default class PriorityGroupPriorityPivot extends Model {
  static name = "PriorityGroupPriorityPivot";
  static entity = "priorityGroupPriorityPivots";

  static primaryKey = ["priorityGroupUuid", "priorityUuid"];

  static fields() {
    return {
      priorityGroupUuid: this.attr(null),
      priorityUuid: this.attr(null),
    };
  }
}
