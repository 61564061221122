import { BaseAdminModel } from "./BaseAdminModel";

export const FUNCTIONAL_REQUIREMENT_CODES = {
  EE_INT: "EE_INT",
  EE_EXT: "EE_EXT",
  EE_ENV: "EE_ENV",
  EE_PLUIE: "EE_PLUIE",
  RO: "RO",
  TP: "TP",
  APRP: "APRP",
  EA: "EA",
  EV: "EV",
  PAI: "PAI",
  TE: "TE",
  TA: "TA",
  TV: "TV",
};

export default class FunctionalRequirement extends BaseAdminModel {
  static name = "FunctionalRequirement";

  static entity = "functionalRequirements";
}
