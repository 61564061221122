import { BaseModel } from "./baseModels";

export default class DocumentImageType extends BaseModel {
  static name = "DocumentImageType";
  static entity = "documentImageTypes";

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(),
      category: this.attr(""),
      label: this.attr(""),
    };
  }
}
