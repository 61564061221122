import { BaseModel } from "./baseModels";
import QuotationValue from "./QuotationValue";
import StatementType from "./StatementType";

export default class ObservationStatement extends BaseModel {
  static name = "ObservationStatement";
  static entity = "observationStatements";

  static rxdbIndexes = ["observation"];

  static fields() {
    return {
      ...super.fields(),
      justification: this.string(""),
      // aggregates
      statementTypeData: this.belongsTo(StatementType, "statementType"),
      quotationValueData: this.belongsTo(QuotationValue, "quotationValue"),
      statementType: this.string(""),
      quotationValue: this.attr(""),
      createdByFirstname: this.string(""),
      modifiedByFirstname: this.string(""),
      createdByLastname: this.string(""),
      modifiedByLastname: this.string(""),
      // foreign keys
      observation: this.string(""),
      objectTypeStatement: this.string(""),
      documents: this.attr([]),

      createdBy: this.string(""),
      modifiedBy: this.string(""),
    };
  }
}
