import { Report as SocioReport } from "@socotec.io/socio-vue-components";
import { Relation } from "@/models/Relation";

export default class Report extends SocioReport {
  static name = "Report";

  static fields() {
    return {
      ...super.fields(),
      perimeter: this.attr(null),
      relations: this.hasMany(Relation, "appEntityId"),
    };
  }
}
