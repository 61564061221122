const AdminHomepage = () =>
  import(
    /* webpackChunkName: "adminHomepage" */ "@/components/amos/admin/AdminHomepage"
  );

const PriorityAdmin = () =>
  import(
    /* webpackChunkName: "PriorityAdmin" */ "@/components/amos/admin/PriorityAdmin"
  );

const PriorityGroupAdmin = () =>
  import(
    /* webpackChunkName: "PriorityGroupAdmin" */ "@/components/amos/admin/PriorityGroupAdmin"
  );

const VentilationAdmin = () =>
  import(
    /* webpackChunkName: "VentilationAdmin" */ "@/components/amos/admin/VentilationAdmin"
  );

const VentilationGroupAdmin = () =>
  import(
    /* webpackChunkName: "VentilationGroupAdmin" */ "@/components/amos/admin/VentilationGroupAdmin"
  );
const DifferabilityAdmin = () =>
  import(
    /* webpackChunkName: "DifferabilityAdmin" */ "@/components/amos/admin/DifferabilityAdmin"
  );

const DifferabilityGroupAdmin = () =>
  import(
    /* webpackChunkName: "DifferabilityGroupAdmin" */ "@/components/amos/admin/DifferabilityGroupAdmin"
  );
const ROIAdmin = () =>
  import(/* webpackChunkName: "ROIAdmin" */ "@/components/amos/admin/ROIAdmin");

const ROIGroupAdmin = () =>
  import(
    /* webpackChunkName: "ROIGroupAdmin" */ "@/components/amos/admin/ROIGroupAdmin"
  );
const ProjectionAdmin = () =>
  import(
    /* webpackChunkName: "ProjectionAdmin" */ "@/components/amos/admin/ProjectionAdmin"
  );

const ProjectionGroupAdmin = () =>
  import(
    /* webpackChunkName: "ProjectionGroupAdmin" */ "@/components/amos/admin/ProjectionGroupAdmin"
  );

const CostScaleAdmin = () =>
  import(
    /* webpackChunkName: "CostScaleAdmin" */ "@/components/amos/admin/CostScaleAdmin"
  );

const CostScaleGroupAdmin = () =>
  import(
    /* webpackChunkName: "CostScaleGroupAdmin" */ "@/components/amos/admin/CostScaleGroupAdmin"
  );

const SourcePricingAdmin = () =>
  import(
    /* webpackChunkName: "SourcePricingAdmin" */ "@/components/amos/admin/SourcePricingAdmin"
  );

const SourcePricingGroupAdmin = () =>
  import(
    /* webpackChunkName: "SourcePricingGroupAdmin" */ "@/components/amos/admin/SourcePricingGroupAdmin"
  );

const RiskAssessmentAdmin = () =>
  import(
    /* webpackChunkName: "RiskAssessmentAdmin" */ "@/components/amos/admin/RiskAssessmentAdmin"
  );

const ActionStatusAdmin = () =>
  import(
    /* webpackChunkName: "ActionStatusAdmin" */ "@/components/amos/admin/ActionStatusAdmin"
  );
const ActionTypeAdmin = () =>
  import(
    /* webpackChunkName: "ActionTypeAdmin" */ "@/components/amos/admin/ActionTypeAdmin"
  );
const AnalyticalAxisAdmin = () =>
  import(
    /* webpackChunkName: "AnalyticalAxisAdmin" */ "@/components/amos/admin/AnalyticalAxisAdmin"
  );
const QuotationAdmin = () =>
  import(
    /* webpackChunkName: "QuotationAdmin" */ "@/components/amos/admin/QuotationAdmin"
  );
const QuotationAnalyticalAxisAdmin = () =>
  import(
    /* webpackChunkName: "QuotationAnalyticalAxisAdmin" */ "@/components/amos/admin/QuotationAnalyticalAxisAdmin"
  );
const QuotationGroupAdmin = () =>
  import(
    /* webpackChunkName: "QuotationGroupAdmin" */ "@/components/amos/admin/QuotationGroupAdmin"
  );
const QuotationGroupTypeAdmin = () =>
  import(
    /* webpackChunkName: "QuotationGroupTypeAdmin" */ "@/components/amos/admin/QuotationGroupTypeAdmin"
  );
const QuotationValueAdmin = () =>
  import(
    /* webpackChunkName: "QuotationValueAdmin" */ "@/components/amos/admin/QuotationValueAdmin"
  );
const StatementTypeAdmin = () =>
  import(
    /* webpackChunkName: "StatementTypeAdmin" */ "@/components/amos/admin/StatementTypeAdmin"
  );
const GlossaryAdmin = () =>
  import(
    /* webpackChunkName: "GlossaryAdmin" */ "@/components/amos/admin/GlossaryAdmin"
  );
const SurveyRuleAdmin = () =>
  import(
    /* webpackChunkName: "SurveyRuleAdmin" */ "@/components/amos/admin/SurveyRuleAdmin"
  );
const ReportTextAdmin = () =>
  import(
    /* webpackChunkName: "ReportTextAdmin" */ "@/components/amos/admin/ReportTextAdmin"
  );
const LabelCertificationLifecycleAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationLifecycleAdmin" */ "@/components/amos/admin/LabelCertificationLifecycleAdmin"
  );
const LabelCertificationTypeAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationTypeAdmin" */ "@/components/amos/admin/LabelCertificationTypeAdmin"
  );
const LabelCertificationLabelAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationLabelAdmin" */ "@/components/amos/admin/LabelCertificationLabelAdmin"
  );
const LabelCertificationVersionAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationVersionAdmin" */ "@/components/amos/admin/LabelCertificationVersionAdmin"
  );
const LabelCertificationPanelAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationPanelAdmin" */ "@/components/amos/admin/LabelCertificationPanelAdmin"
  );
const LabelCertificationLevelAdmin = () =>
  import(
    /* webpackChunkName: "LabelCertificationLevelAdmin" */ "@/components/amos/admin/LabelCertificationLevelAdmin"
  );
const RiskAssessmentGroupAdmin = () =>
  import(
    /* webpackChunkName: "RiskAssessmentGroupAdmin" */ "@/components/amos/admin/RiskAssessmentGroupAdmin"
  );
const ReferencialBindingAdmin = () =>
  import(
    /* webpackChunkName: "RiskAssessmentGroupAdmin" */ "@/components/amos/admin/ReferencialBindingAdmin"
  );
const ReportTemplateTypeAdmin = () =>
  import(
    /* webpackChunkName: "RiskAssessmentGroupAdmin" */ "@/components/amos/admin/ReportTemplateTypeAdmin"
  );
const ComponentTypologyAdmin = () =>
  import(
    /* webpackChunkName: "RiskAssessmentGroupAdmin" */ "@/components/amos/admin/ComponentTypologyAdmin"
  );

export default [
  {
    path: "",
    name: "admin",
    component: AdminHomepage,
  },
  {
    path: "priority",
    name: "priority",
    component: PriorityAdmin,
  },
  {
    path: "priorityGroup",
    name: "priorityGroup",
    component: PriorityGroupAdmin,
  },
  {
    path: "ventilation",
    name: "ventilation",
    component: VentilationAdmin,
  },
  {
    path: "ventilationGroup",
    name: "ventilationGroup",
    component: VentilationGroupAdmin,
  },
  {
    path: "differability",
    name: "differability",
    component: DifferabilityAdmin,
  },
  {
    path: "differabilityGroup",
    name: "differabilityGroup",
    component: DifferabilityGroupAdmin,
  },
  {
    path: "ROI",
    name: "ROI",
    component: ROIAdmin,
  },
  {
    path: "ROIGroup",
    name: "ROIGroup",
    component: ROIGroupAdmin,
  },
  {
    path: "projection",
    name: "projection",
    component: ProjectionAdmin,
  },
  {
    path: "projectionGroup",
    name: "projectionGroup",
    component: ProjectionGroupAdmin,
  },
  {
    path: "costScale",
    name: "costScale",
    component: CostScaleAdmin,
  },
  {
    path: "costScaleGroup",
    name: "costScaleGroup",
    component: CostScaleGroupAdmin,
  },
  {
    path: "sourcePricing",
    name: "sourcePricing",
    component: SourcePricingAdmin,
  },
  {
    path: "sourcePricingGroup",
    name: "sourcePricingGroup",
    component: SourcePricingGroupAdmin,
  },
  {
    path: "riskAssessment",
    name: "riskAssessment",
    component: RiskAssessmentAdmin,
  },
  {
    path: "riskAssessmentGroup",
    name: "riskAssessmentGroup",
    component: RiskAssessmentGroupAdmin,
  },
  {
    path: "actionStatus",
    name: "actionStatus",
    component: ActionStatusAdmin,
  },
  {
    path: "actionType",
    name: "actionType",
    component: ActionTypeAdmin,
  },
  {
    path: "analyticalAxis",
    name: "analyticalAxis",
    component: AnalyticalAxisAdmin,
  },
  {
    path: "quotation",
    name: "quotation",
    component: QuotationAdmin,
  },
  {
    path: "quotationAnalyticalAxis",
    name: "quotationAnalyticalAxis",
    component: QuotationAnalyticalAxisAdmin,
  },
  {
    path: "quotationGroup",
    name: "quotationGroup",
    component: QuotationGroupAdmin,
  },
  {
    path: "quotationGroupType",
    name: "quotationGroupType",
    component: QuotationGroupTypeAdmin,
  },
  {
    path: "quotationValue",
    name: "quotationValue",
    component: QuotationValueAdmin,
  },
  {
    path: "statementType",
    name: "statementType",
    component: StatementTypeAdmin,
  },
  {
    path: "glossary",
    name: "glossary",
    component: GlossaryAdmin,
  },
  {
    path: "surveyRule",
    name: "surveyRule",
    component: SurveyRuleAdmin,
  },
  {
    path: "reportText",
    name: "reportText",
    component: ReportTextAdmin,
  },
  {
    path: "labelCertificationLifecycle",
    name: "labelCertificationLifecycle",
    component: LabelCertificationLifecycleAdmin,
  },
  {
    path: "labelCertificationType",
    name: "labelCertificationType",
    component: LabelCertificationTypeAdmin,
  },
  {
    path: "labelCertificationLabel",
    name: "labelCertificationLabel",
    component: LabelCertificationLabelAdmin,
  },
  {
    path: "labelCertificationVersion",
    name: "labelCertificationVersion",
    component: LabelCertificationVersionAdmin,
  },
  {
    path: "labelCertificationPanel",
    name: "labelCertificationPanel",
    component: LabelCertificationPanelAdmin,
  },
  {
    path: "labelCertificationLevel",
    name: "labelCertificationLevel",
    component: LabelCertificationLevelAdmin,
  },
  {
    path: "objectTypeStatement",
    name: "objectTypeStatement",
    component: ReferencialBindingAdmin,
  },
  {
    path: "reportTemplateType",
    name: "reportTemplateType",
    component: ReportTemplateTypeAdmin,
  },
  {
    path: "componentTypology",
    name: "componentTypology",
    component: ComponentTypologyAdmin,
  },
];
