import { RAPSOTEC_CT_PATHES_TO_AXES_CODES } from "@/utils/const";
import { BaseReferencialModel } from "./baseModels";

export default class ReferencialCtNode extends BaseReferencialModel {
  static name = "ReferencialCtNode";
  static primaryKey = "uuid";
  static entity = "referencialCtNodes";

  static fields() {
    return {
      ...super.fields(),
      // foreign keys
      ctNode: this.string(""),

      // points to RapsotecNodeAmos
      objectId: this.attr(""),

      // aggregates
      referencialVersion: this.string(""),
      analyticalAxis: this.string(""),
    };
  }

  static beforeCreate(model) {
    const entities = this.store().state.entities;
    const analyticalAxisState = entities.analyticalAxes;
    const [rootPath] = model.path.split(".");
    const axisCode = RAPSOTEC_CT_PATHES_TO_AXES_CODES[rootPath];

    if (!axisCode) {
      console.error(
        `Could not find analytical axis for disposition node ${model.path}, data might not be loaded correctly`
      );
    }
    model.analyticalAxis = analyticalAxisState.codeToUuidMap[axisCode];
  }
}
