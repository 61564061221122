import { BaseAdminGroupModel } from "./baseModels";

export default class CostScaleGroup extends BaseAdminGroupModel {
  static name = "CostScaleGroup";

  static entity = "costScaleGroups";

  static fields() {
    return {
      ...super.fields(),
      costScales: this.attr([]),
    };
  }

  get itemsList() {
    return this.costScales;
  }
}
