import {
  JsonValidationSchema,
  numberValidation,
  stringValidation,
  booleanValidation,
  stringListValidation,
} from "./JsonValidationSchema";

export class ObjectTypeStatement extends JsonValidationSchema {
  static primaryKey = "uuid";
  static entity = "object_type_statement";

  @stringValidation
  uuid;
  @stringValidation
  updatedAt;

  @stringValidation
  createdAt;

  @booleanValidation
  displayed;

  @stringValidation
  contentType;

  @stringListValidation
  actionTypes;

  @booleanValidation
  isApplicableToChildren;

  @numberValidation
  ordering;

  @booleanValidation
  isArchived;

  @stringValidation
  statementType;

  @stringValidation
  quotationValue;

  @stringValidation
  analyticalAxis;

  @stringValidation
  objectId;

  @stringValidation
  analyticalAxisCode;

  rxdbIndedexs = [];
}
