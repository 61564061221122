import { AmosReferencial } from "./baseModels";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { SubMission } from "@/models";
export default class AmosComponentType extends AmosReferencial {
  static name = "AmosComponentType";
  static entity = "amosComponentType";

  static fields() {
    return {
      ...super.fields(),
      aosComponentTypeData: this.belongsTo(
        AosVuexOrmModel["AosComponentType"],
        "aosComponentTypeUuid"
      ),
      aosComponentNodeData: this.belongsTo(
        AosVuexOrmModel["AosComponentNode"],
        "referencialNodeUuid"
      ),
      submissionData: this.hasManyBy(SubMission, "componentTypes"),
      // foreign keys
      aosComponentTypeUuid: this.string(""),
    };
  }
}
