import SubObservation from "./SubObservation";

export default class DocumentReview extends SubObservation {
  static name = "DocumentReview";
  static entity = "documentReviews";

  static fields() {
    return {
      ...super.fields(),
      label: this.string(""),
      datetime: this.attr(),
      issueDate: this.attr(),
      source: this.string(""),
      ref: this.string(""),
      theoreticalPeriodicity: this.attr(""),
      termsOfApplication: this.string(""),
      pollsRules: this.string(""),
      regulatoryReferences: this.string(""),
      qualificationRequired: this.string(""),
      responsible: this.string(""),
      datetimeFormat: this.attr(),
      issueDateFormat: this.attr(),

      // foreign keys
      typologyType: this.string(""),
    };
  }
}
