import VisitChildren from "./visit/visit-children";
import projectMiddleware from "@/router/middleware/projectMiddleware";

const Visit = () =>
  import(
    /* webpackChunkName: "Visit" */
    "@/components/amos/visits/Visit"
  );

export default [
  {
    path: ":projectUuid",
    component: Visit,
    children: VisitChildren,
    beforeEnter: projectMiddleware,
  },
];
