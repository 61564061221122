import { BaseReferencialModel } from "./baseModels";
import { DISPOSITION_PATHES_TO_ANALYTICAL_AXES } from "@/utils/const";

export default class DispositionNode extends BaseReferencialModel {
  static name = "DispositionNode";
  static primaryKey = "uuid";
  static entity = "dispositionNodes";

  static fields() {
    return {
      ...super.fields(),
      // foreign keys
      dispositionType: this.string(""),
      analyticalAxis: this.string(""),

      // aggregates
      referencialVersion: this.string(""),
    };
  }

  static beforeCreate(model) {
    if (model.isArchived) return false;

    const entities = this.store().state.entities;
    const dispositionType =
      entities.dispositionTypes.referencialUuidMap[model.uuid];

    if (!dispositionType) {
      this.commit((state) => {
        state.notFoundItems.add(model.path);
      });
    }
    const analyticalAxisState = entities.analyticalAxes;
    const [rootPath] = model.path.split(".");
    const axisCode = DISPOSITION_PATHES_TO_ANALYTICAL_AXES[rootPath];

    if (!axisCode) {
      console.error(
        `Could not find analytical axis for disposition node ${model.path}, data might not be loaded correctly`
      );
    }
    model.analyticalAxis = analyticalAxisState.codeToUuidMap[axisCode];
    model.dispositionType = dispositionType?.uuid;
    model.referencialVersion = dispositionType?.referencialVersion;
  }
}
