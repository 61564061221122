class JsonValidationSchema {
  // used to test the type of a class. If you inherit from jsonValidationSchema, this is how schemaGneration knows it s a JsonValidationSchemaClass
  static ___class___type() {
    return "JsonValidationSchema";
  }

  // return types as string of class attribute
  static fields() {
    return this.types;
  }

  constructor() {
    // child model MUST define primaryKey and entity
    if (
      typeof this.constructor.primaryKey !== "string" ||
      !this.constructor.uuid
    ) {
      throw new Error(
        `Class "${this.constructor.name}" must define a static string attribute "primaryKey".`
      );
    }
    if (
      typeof this.constructor.entity !== "string" ||
      !this.constructor.entity
    ) {
      throw new Error(
        `Class "${this.constructor.name}" must define a static string attribute "entity".`
      );
    }
  }
}

function numberValidation(target, key) {
  // if types is not defined on the class, defines it.
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "number",
  };
}

function stringValidation(target, key) {
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "string",
  };
}
function booleanValidation(target, key) {
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "boolean",
  };
}

function objectvalidation(target, key) {
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "object",
  };
}

function stringListValidation(target, key) {
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "array",
    subType: "string",
  };
}

function objectListValidation(target, key) {
  if (!target.constructor.types) {
    target.constructor.types = {};
  }
  target.constructor.types[key] = {
    type: "array",
    subType: "object",
  };
}

export {
  JsonValidationSchema,
  numberValidation,
  stringValidation,
  booleanValidation,
  objectvalidation,
  stringListValidation,
  objectListValidation,
};
