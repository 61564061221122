import { BaseModel } from "./baseModels";

export class UserPerimeter extends BaseModel {
  static name = "UserPerimeter";
  static entity = "userPerimeter";
  static primaryKey = "uuid";
  static rxdbIndexes = ["projectId"];

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(null),
      projectId: this.string(""),
      userId: this.attr(""),
      aosItemUuids: this.attr([]),
      aosObservableUuids: this.attr([]),
      assets: this.attr({}),
      createdOrUpdatedOffline: this.attr(false),
      data: this.attr({}),
      createdAt: this.attr(""),
      updatedAt: this.attr(""),
      isArchived: this.attr(""),
      observationUuids: this.attr([]),
      bimdataTokenMappingOffline: this.attr({}),
      assetUuidCollection: this.attr({}),
      aosItemUuidCollection: this.attr({}),
      offlineDocumentUuids: this.attr([]),
      observationStatementUuid: this.attr([]),
      offlineTagsUuids: this.attr([]),
    };
  }
}
