import { BaseModel } from "./baseModels";
import { BaseAdminModel } from "./BaseAdminModel";

export class LabelCertificationLifecycle extends BaseAdminModel {
  static name = "LabelCertificationLifecycle";
  static entity = "labelCertificationLifecycle";
}

export class LabelCertificationType extends BaseAdminModel {
  static name = "LabelCertificationType";
  static entity = "labelCertificationType";
}

export class LabelCertificationLabel extends BaseModel {
  static name = "LabelCertificationLabel";
  static entity = "labelCertificationLabel";

  static fields() {
    return {
      ...super.fields(),

      label: this.string(""),
      isArchived: this.boolean(false),
      ordering: this.attr(0),
      labelCertificationLifecycleData: this.belongsTo(
        LabelCertificationLifecycle,
        "labelCertificationLifecycle"
      ),
      labelCertificationTypeData: this.belongsTo(
        LabelCertificationType,
        "labelCertificationType"
      ),

      // Foreign keys
      labelCertificationLifecycle: this.string(""),
      labelCertificationType: this.string(""),

      // Aggregate data
      labelCertificationVersionsList: this.attr([]),
      labelCertificationPanelsList: this.attr([]),
      labelCertificationLevelsList: this.attr([]),
    };
  }

  static getType(labelItem) {
    return LabelCertificationType.find(labelItem?.labelCertificationType);
  }
  static getLifecycle(labelItem) {
    return LabelCertificationLifecycle.find(
      labelItem?.labelCertificationLifecycle
    );
  }
}

export class LabelCertificationVersion extends BaseModel {
  static name = "LabelCertificationVersion";
  static entity = "labelCertificationVersion";

  static fields() {
    return {
      ...super.fields(),
      labelCertificationLabelData: this.belongsTo(
        LabelCertificationLabel,
        "labelCertificationLabel"
      ),
      label: this.string(""),
      isArchived: this.boolean(false),
      ordering: this.attr(0),

      // Foreign key
      labelCertificationLabel: this.string(""),
    };
  }
  static getLabel(VersionItem) {
    return LabelCertificationLabel.find(VersionItem?.labelCertificationLabel);
  }
  static getType(VersionItem) {
    const label = this.getLabel(VersionItem);
    return LabelCertificationType.find(label?.labelCertificationType);
  }
  static getLifecycle(VersionItem) {
    const label = this.getLabel(VersionItem);
    return LabelCertificationLifecycle.find(label?.labelCertificationLifecycle);
  }
}

export class LabelCertificationPanel extends BaseModel {
  static name = "LabelCertificationPanel";
  static entity = "labelCertificationPanel";

  static fields() {
    return {
      ...super.fields(),
      labelCertificationVersionData: this.belongsTo(
        LabelCertificationVersion,
        "labelCertificationVersion"
      ),
      label: this.string(""),
      isArchived: this.boolean(false),
      ordering: this.attr(0),

      // Foreign key
      labelCertificationVersion: this.string(""),
    };
  }

  static getVersion(panelItem) {
    return LabelCertificationVersion.find(panelItem?.labelCertificationVersion);
  }
  static getLabel(panelItem) {
    const version = this.getVersion(panelItem);
    return LabelCertificationLabel.find(version?.labelCertificationLabel);
  }
  static getType(panelItem) {
    const label = this.getLabel(panelItem);
    return LabelCertificationType.find(label?.labelCertificationType);
  }
  static getLifecycle(panelItem) {
    const label = this.getLabel(panelItem);
    return LabelCertificationLifecycle.find(label?.labelCertificationLifecycle);
  }
}

export class LabelCertificationLevel extends BaseModel {
  static name = "LabelCertificationLevel";
  static entity = "labelCertificationLevel";

  static fields() {
    return {
      ...super.fields(),
      labelCertificationPanelData: this.belongsTo(
        LabelCertificationPanel,
        "labelCertificationPanel"
      ),
      label: this.string(""),
      isArchived: this.boolean(false),
      ordering: this.attr(0),

      // Foreign key
      labelCertificationPanel: this.string(""),
    };
  }

  static getPanel(levelItem) {
    return LabelCertificationPanel.find(levelItem?.labelCertificationPanel);
  }
  static getVersion(levelItem) {
    const panel = this.getPanel(levelItem);
    return LabelCertificationVersion.find(panel?.labelCertificationVersion);
  }
  static getLabel(levelItem) {
    const version = this.getVersion(levelItem);
    return LabelCertificationLabel.find(version?.labelCertificationLabel);
  }
  static getType(levelItem) {
    const label = this.getLabel(levelItem);
    return LabelCertificationType.find(label?.labelCertificationType);
  }
  static getLifecycle(levelItem) {
    const label = this.getLabel(levelItem);
    return LabelCertificationLifecycle.find(label?.labelCertificationLifecycle);
  }
}
