import { StandByReport as SocioStandByReport } from "@socotec.io/socio-vue-components";

export default class StandByReport extends SocioStandByReport {
  static name = "StandByReport";

  static fields() {
    return {
      ...super.fields(),
      // UUID of StandByReport Service
      StandByReportUuid: this.string(""),
      createdBy: this.string(""),
      perimeter: this.attr(null),
    };
  }
}
