const ScenariosList = () =>
  import(
    /* webpackChunkName: "ScenariosList" */ "@/components/amos/visits/visit/arbitration/ScenariosList"
  );

const Scenario = () =>
  import(
    /* webpackChunkName: "Scenario" */ "@/components/amos/visits/visit/arbitration/Scenario"
  );

export default [
  {
    path: "",
    name: "arbitration",
    component: ScenariosList,
  },
  {
    path: "scenario/:scenarioUuid?",
    name: "scenario",
    component: Scenario,
  },
];
