import { BaseModel } from "./baseModels";
import ProjectConfigAnalyticalAxis from "./ProjectConfigAnalyticalAxis";

export default class ProjectConfig extends BaseModel {
  static name = "ProjectConfig";
  static entity = "projectConfig";

  static fields() {
    return {
      ...super.fields(),
      projectConfigAnalyticalAxes: this.hasMany(
        ProjectConfigAnalyticalAxis,
        "projectConfig"
      ),

      // used to retrieve AAxis from rxdb when working offline.
      analyticalAxisUuid: this.attr([]),
      // foreign keys
      project: this.attr(),
      submissions: this.attr([]),
      priorityGroup: this.attr(null),
      ventilationGroup: this.attr(null),
      differabilityGroup: this.attr(null),
      rOIGroup: this.attr(null),
      sourcePricingGroup: this.attr(null),
      costScaleGroup: this.attr(null),
      projectionGroup: this.attr(null),

      isConfidential: this.attr(false),
      missionContext: this.attr(""),
      startDate: this.attr(null),
      duration: this.attr(0),
      hasDisposition: this.attr(false),
      hasQuotation: this.attr(false),
      hasRisk: this.attr(false),
      hasPhoto: this.attr(false),
      hasAction: this.attr(false),
      hasPriority: this.attr(false),
      hasAmount: this.attr(false),
      hasROI: this.attr(false),
      hasVentilation: this.attr(false),
      hasDifferability: this.attr(false),
      hasYear: this.attr(false),
      hasSourcePricing: this.attr(false),
      hasCostScale: this.attr(false),
      hasProjection: this.attr(false),

      siteCharacteristics: this.attr(),
      buildingCharacteristics: this.attr(),
      infrastructureCharacteristics: this.attr(),
      storeyCharacteristics: this.attr(),
      zoneCharacteristics: this.attr(),
      spaceCharacteristics: this.attr(),
      componentCharacteristics: this.attr(),

      referenceText: this.string(""),
      reportConfigGraphicsList: this.attr([]),
      reportTemplateFileType: this.string(""),
      reportClientLogo: this.attr(),
      reportLanguage: this.string(""),
      reportTitle: this.attr(""),
      hasRedFlagSynthesis: this.boolean(),
      hasGrouping: this.boolean(),
    };
  }

  get actionConfig() {
    return {
      ROI: () => (this.hasROI ? this.rOIGroup : null),
      ventilation: () => (this.hasVentilation ? this.ventilationGroup : null),
      costScale: () => (this.hasCostScale ? this.costScaleGroup : null),
      projection: () => (this.hasProjection ? this.projectionGroup : null),
      priority: () => (this.hasPriority ? this.priorityGroup : null),
      differability: () =>
        this.hasDifferability ? this.differabilityGroup : null,
      sourcePricing: () =>
        this.hasSourcePricing ? this.sourcePricingGroup : null,
      analyticalAxes: () => null,
    };
  }
}
