import { useServiceFactory } from "@socotec.io/socio-grpc-api/connect/index.js";

let lastValidAccessToken = "";
const tokenGetter = () => {
  return lastValidAccessToken;
};
export const useService = useServiceFactory(
  tokenGetter,
  process.env.VUE_APP_SOCIO_GRPC_API_ENV,
  process.env.VUE_APP_SERVICE_SLUG
);
export const initServiceConnect = (accessToken) => {
  lastValidAccessToken = accessToken;
};

export const updateAccessToken = (accessToken) => {
  lastValidAccessToken = accessToken;
};
