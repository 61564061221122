const schemas = {
  agency: {
    schema: {
      title: "agency schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        code: {
          type: ["string", "number"],
        },
        name: {
          type: "string",
        },
        address: {
          type: "string",
        },
        city: {
          type: "string",
        },
        country: {
          type: "string",
        },
        postalCode: {
          type: "string",
        },
        agencyGroup: {
          type: "string",
        },
        telephoneNumber: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  agency_group: {
    schema: {
      title: "agencyGroup schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        name: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  amos_aos_object_type: {
    schema: {
      title: "amosAosObjectType schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  amos_component_type: {
    schema: {
      title: "amosComponentType schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        referencialNodePath: {
          type: "string",
        },
        referencialNodeLabel: {
          type: "string",
        },
        referencialNodeUuid: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
        referencialNodeLevel: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        description: {
          type: "string",
        },
        referencialNodeLabelFr: {
          type: "string",
        },
        referencialNodeLabelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        aosComponentTypeUuid: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_observable: {
    schema: {
      title: "aosObservables schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        assetType: {
          type: "string",
        },
        assetUuid: {
          type: "string",
        },
        aosItem: {
          type: "string",
          maxLength: 200,
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        assetModel: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
      indexes: ["aosItem"],
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.isArchived;
        return oldDoc;
      },
      2: (oldDoc) => {
        oldDoc.aosItem =
          oldDoc.aosItem === undefined
            ? oldDoc.aosItem
            : oldDoc.aosItem.toString();
        return oldDoc;
      },
    },
  },
  archive_bimdata: {
    schema: {
      title: "archiveBimdata schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        createdAt: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        cloudId: {
          type: "string",
        },
        projectUuid: {
          type: "string",
        },
        projectId: {
          type: "string",
        },
        modelIds: {
          type: "array",
          items: {
            type: "string",
          },
        },
        documentIds: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
      attachments: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.blobData;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  asset_tag: {
    schema: {
      title: "assetTags schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        editable: {
          type: "boolean",
        },
        parent: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  site_tag: {
    schema: {
      title: "siteTags schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        tag: {
          type: "string",
        },
        item: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  building_tag: {
    schema: {
      title: "buildingTags schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        tag: {
          type: "string",
        },
        item: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  building_asset: {
    schema: {
      title: "buildingAssets schema",
      version: 5,
      primaryKey: "uuid",
      type: "object",
      properties: {
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        site: {
          type: "string",
        },
        tags: {
          type: "array",
          items: {
            type: "string",
          },
        },
        code: {
          type: ["string", "number"],
        },
        actors: {
          type: "array",
          items: {
            type: "object",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        delete oldDoc.isCaseAsset;
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  cached_project: {
    schema: {
      title: "cachedProjects schema",
      version: 2,
      primaryKey: "project",
      type: "object",
      properties: {
        createdAt: {
          type: "string",
        },
        project: {
          type: "string",
          maxLength: 100,
        },
        user: {
          type: "string",
        },
        status: {
          type: "string",
        },
        progress: {
          type: "number",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.uuid;
        delete oldDoc.updatedAt;
        delete oldDoc.displayed;
        return oldDoc;
      },
    },
  },
  case: {
    schema: {
      title: "cases schema",
      version: 0,
      primaryKey: "caseNumber",
      type: "object",
      properties: {
        uuid: {
          type: "string",
        },
        caseNumber: {
          type: "string",
          maxLength: 100,
        },
        caseName: {
          type: "string",
        },
        caseAgencyCode: {
          type: "string",
        },
        label: {
          type: "string",
        },
        status: {
          type: "string",
        },
        opeStartDate: {
          type: "string",
        },
        postcode: {
          type: "string",
        },
        city: {
          type: "string",
        },
        address: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        rictPublicationDate: {
          type: "string",
        },
        initialAmount: {
          type: "string",
        },
        finalAmount: {
          type: "string",
        },
        estimatedDuration: {
          type: "string",
        },
        signDate: {
          type: "string",
        },
        totalBeforeTax: {
          type: "string",
        },
        isNationalContract: {
          type: "boolean",
        },
        facturationCalculMode: {
          type: "string",
        },
        caseManagerCode: {
          type: "string",
        },
        managerFirstName: {
          type: "string",
        },
        managerLastName: {
          type: "string",
        },
        managerEmail: {
          type: "string",
        },
        managerUsermanagementUuid: {
          type: "string",
        },
        orderedSince: {
          type: "string",
        },
        closedSince: {
          type: "string",
        },
        clientUuid: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        createAt: {
          type: "string",
        },
        forcedStatus: {
          type: "string",
        },
        userProfileId: {
          type: "string",
        },
        clientLabel: {
          type: "string",
        },
        contractingAgencyCode: {
          type: "string",
        },
        emittedDocs: {
          type: "string",
        },
        userUuid: {
          type: "string",
        },
        caseMainRecipient: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  client: {
    schema: {
      title: "clients schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        name: {
          type: "string",
        },
        postcode: {
          type: "string",
        },
        city: {
          type: "string",
        },
        address: {
          type: "string",
        },
        telephone: {
          type: "string",
        },
        mobile: {
          type: "string",
        },
        email: {
          type: "string",
        },
        chronorapso: {
          type: "string",
        },
        contactCompany: {
          type: "string",
        },
        contactFirstName: {
          type: "string",
        },
        contactLastName: {
          type: "string",
        },
        contactRole: {
          type: "string",
        },
        contactPostcode: {
          type: "string",
        },
        contactCity: {
          type: "string",
        },
        contactAddress: {
          type: "string",
        },
        contactTelephone: {
          type: "string",
        },
        contactMobile: {
          type: "string",
        },
        contactEmail: {
          type: "string",
        },
        decisionCenterCode: {
          type: "string",
        },
        decisionCenterLabel: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  component_asset: {
    schema: {
      title: "componentAssets schema",
      version: 7,
      primaryKey: "uuid",
      type: "object",
      properties: {
        building: {
          type: "string",
        },
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        site: {
          type: "string",
        },
        siteData: {
          type: "object",
        },
        code: {
          type: ["string", "number"],
        },
        observationComment: {
          type: "string",
        },
        description: {
          type: "string",
        },
        offlineAosObservableUuidDependency: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        delete oldDoc.createdOrUpdatedOffline;
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  disposition_node: {
    schema: {
      title: "dispositionNodes schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        path: {
          type: "string",
        },
        level: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        readablePath: {
          type: "string",
        },
        modificationOf: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        updatedAt: {
          type: "string",
        },
        modifiedByUuid: {
          type: "string",
        },
        conditionalTitles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        conditionalDisplay: {
          type: "array",
          items: {
            type: "object",
          },
        },
        displayedCountriesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        children: {
          type: "array",
          items: {
            type: "string",
          },
        },
        parentNode: {
          type: "string",
        },
        objectId: {
          type: "string",
        },
        manuallyCreated: {
          type: "boolean",
        },
        isDisplay: {
          type: "boolean",
        },
        isOpen: {
          type: "boolean",
        },
        isSelected: {
          type: "boolean",
        },
        referencialName: {
          type: "string",
        },
        rootNodePath: {
          type: "string",
        },
        fullLabel: {
          type: "string",
        },
        breadcrumbs: {
          type: "array",
          items: {
            type: "string",
          },
        },
        originalPath: {
          type: "string",
        },
        dispositionType: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.dispositionsCount;
        delete oldDoc.smallestQuotationValue;
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  disposition: {
    schema: {
      title: "dispositions schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        observation: {
          type: "string",
        },
        actionsCount: {
          type: "number",
        },
        smallestQuotationValue: {
          type: "number",
        },
        analyticalAxis: {
          type: "string",
        },
        aosObservablesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        referencialNodeUuid: {
          type: "string",
        },
        aosItems: {
          type: "array",
          items: {
            type: "string",
          },
        },
        perimeter: {
          type: "string",
        },
        dispositionType: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  document_review: {
    schema: {
      title: "documentReviews schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        observation: {
          type: "string",
        },
        actionsCount: {
          type: "number",
        },
        smallestQuotationValue: {
          type: "number",
        },
        analyticalAxis: {
          type: "string",
        },
        aosObservablesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        referencialNodeUuid: {
          type: "string",
        },
        aosItems: {
          type: "array",
          items: {
            type: "string",
          },
        },
        perimeter: {
          type: "string",
        },
        label: {
          type: "string",
        },
        datetime: {
          type: "string",
        },
        issueDate: {
          type: "string",
        },
        source: {
          type: "string",
        },
        ref: {
          type: "string",
        },
        theoreticalPeriodicity: {
          type: "string",
        },
        termsOfApplication: {
          type: "string",
        },
        pollsRules: {
          type: "string",
        },
        regulatoryReferences: {
          type: "string",
        },
        qualificationRequired: {
          type: "string",
        },
        responsible: {
          type: "string",
        },
        datetimeFormat: {
          type: "string",
        },
        issueDateFormat: {
          type: "string",
        },
        typologyType: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  referencial_ct_node: {
    schema: {
      title: "referencialCtNodes schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        path: {
          type: "string",
        },
        level: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        readablePath: {
          type: "string",
        },
        modificationOf: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        updatedAt: {
          type: "string",
        },
        modifiedByUuid: {
          type: "string",
        },
        conditionalTitles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        conditionalDisplay: {
          type: "array",
          items: {
            type: "object",
          },
        },
        displayedCountriesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        children: {
          type: "array",
          items: {
            type: "string",
          },
        },
        parentNode: {
          type: "string",
        },
        objectId: {
          type: "string",
        },
        manuallyCreated: {
          type: "boolean",
        },
        isDisplay: {
          type: "boolean",
        },
        isOpen: {
          type: "boolean",
        },
        isSelected: {
          type: "boolean",
        },
        referencialName: {
          type: "string",
        },
        rootNodePath: {
          type: "string",
        },
        fullLabel: {
          type: "string",
        },
        breadcrumbs: {
          type: "array",
          items: {
            type: "string",
          },
        },
        originalPath: {
          type: "string",
        },
        ctNode: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  ct_node_observation: {
    schema: {
      title: "ctNodeObservations schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        observation: {
          type: "string",
        },
        actionsCount: {
          type: "number",
        },
        smallestQuotationValue: {
          type: "number",
        },
        analyticalAxis: {
          type: "string",
        },
        aosObservablesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        referencialNodeUuid: {
          type: "string",
        },
        aosItems: {
          type: "array",
          items: {
            type: "string",
          },
        },
        perimeter: {
          type: "string",
        },
        ctNode: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  action: {
    schema: {
      title: "actions schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        date: {
          type: "string",
        },
        dateFormat: {
          type: "string",
        },
        lifespanExtension: {
          type: "number",
        },
        isSuggested: {
          type: "boolean",
        },
        description: {
          type: "string",
        },
        totalAmount: {
          type: "number",
        },
        quantity: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        importantFieldsUpdatedAt: {
          type: "string",
        },
        isInSentReport: {
          type: "boolean",
        },
        companies: {
          type: "array",
          items: {
            type: "string",
          },
        },
        contributors: {
          type: "array",
          items: {
            type: "string",
          },
        },
        previousRelatedAction: {
          type: "string",
        },
        actionRef: {
          type: "string",
        },
        priority: {
          type: "string",
        },
        periodicity: {
          type: "string",
        },
        status: {
          type: "string",
        },
        differability: {
          type: "string",
        },
        ventilation: {
          type: "string",
        },
        roi: {
          type: "string",
        },
        projection: {
          type: "string",
        },
        costScale: {
          type: "string",
        },
        sourcePricing: {
          type: "string",
        },
        observationsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        createdByEmail: {
          type: "string",
        },
        modifiedByEmail: {
          type: "string",
        },
        createdByFirstname: {
          type: "string",
        },
        modifiedByFirstname: {
          type: "string",
        },
        createdByLastname: {
          type: "string",
        },
        modifiedByLastname: {
          type: "string",
        },
        isInherited: {
          type: "boolean",
        },
        analyticalAxesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        periodicityString: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.contributorsList;
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  document: {
    schema: {
      title: "documents schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        createdAt: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        relatedObjectsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        docType: {
          type: "string",
        },
        docStatus: {
          type: "string",
        },
        tagsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        servicesRelatedNameList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        originId: {
          type: "string",
        },
        oldOriginId: {
          type: "string",
        },
        origin: {
          type: "string",
        },
        gedProjectId: {
          type: "string",
        },
        name: {
          type: "string",
        },
        source: {
          type: "string",
        },
        ref: {
          type: "string",
        },
        datetime: {
          type: "string",
        },
        version: {
          type: "string",
        },
        metaDatas: {
          type: "object",
        },
        bimFileUrl: {
          type: "string",
        },
        isActive: {
          type: "boolean",
        },
        mimeType: {
          type: "string",
        },
        generatedBy: {
          type: "string",
        },
        documentUuid: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        datetimeFormat: {
          type: "string",
        },
        perimeter: {
          type: "string",
        },
        isImported: {
          type: "string",
        },
        offlineCreated: {
          type: "boolean",
        },
        data: {
          type: "object",
        },
        relatedItems: {
          type: "object",
        },
        thumbnail: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
      attachments: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        oldDoc.isActive =
          oldDoc.isActive === undefined
            ? oldDoc.isActive
            : oldDoc.isActive === "true";
        return oldDoc;
      },
    },
  },
  drawing: {
    schema: {
      title: "drawing schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        createdAt: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        drawingId: {
          type: "string",
        },
        cloudId: {
          type: "string",
        },
        projectId: {
          type: "string",
        },
        modelId: {
          type: "string",
        },
        type: {
          type: "string",
        },
        content: {
          type: "string",
        },
        offlineCreated: {
          type: "boolean",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  observation: {
    schema: {
      title: "observations schema",
      version: 11,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        origin: {
          type: "string",
        },
        metadata: {
          type: "object",
        },
        ctComment: {
          type: "string",
        },
        refCode: {
          type: "string",
        },
        isValidated: {
          type: "boolean",
        },
        perimeter: {
          type: "string",
        },
        isInSentReport: {
          type: "boolean",
        },
        isArchived: {
          type: "boolean",
        },
        aosObservablesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        amosDocumentsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        riskAssessment: {
          type: "string",
        },
        actionsCount: {
          type: "number",
        },
        smallestQuotationValue: {
          type: "number",
        },
        smallestQuotationStatement: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
        statementsCount: {
          type: "number",
        },
        createdByEmail: {
          type: "string",
        },
        modifiedByEmail: {
          type: "string",
        },
        createdByFirstname: {
          type: "string",
        },
        modifiedByFirstname: {
          type: "string",
        },
        createdByLastname: {
          type: "string",
        },
        modifiedByLastname: {
          type: "string",
        },
        documentsUrlAndNames: {
          type: "array",
          items: {
            type: "string",
          },
        },
        dispositionTypesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        typologyTypesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        ctNodesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        functionalRequirementsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        offlineRequestBuildMetaData: {
          type: "object",
        },
        dispositionUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
        synchronisationState: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
      8: (oldDoc) => {
        oldDoc.refCode =
          oldDoc.refCode === undefined
            ? oldDoc.refCode
            : parseFloat(oldDoc.refCode);
        return oldDoc;
      },
      9: (oldDoc) => {
        oldDoc.refCode =
          oldDoc.refCode === undefined
            ? oldDoc.refCode
            : oldDoc.refCode.toString();
        return oldDoc;
      },
      10: (oldDoc) => {
        delete oldDoc.ctObservation;
        return oldDoc;
      },
      11: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  b_i_m_data_pin: {
    schema: {
      title: "pin schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        createdAt: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        guid: {
          type: "string",
        },
        documentOriginId: {
          type: "string",
        },
        modelId: {
          type: "string",
        },
        pageNumber: {
          type: "string",
        },
        x: {
          type: "string",
        },
        y: {
          type: "string",
        },
        observation: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        offlineCreated: {
          type: "boolean",
        },
        submission: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  project: {
    schema: {
      title: "projects schema",
      version: 1,
      primaryKey: "chronorapso",
      type: "object",
      properties: {
        uuid: {
          type: "string",
        },
        projectId: {
          type: "string",
        },
        originId: {
          type: "string",
        },
        atlasMethodo: {
          type: "string",
        },
        autoUpdatedAt: {
          type: "string",
        },
        updatedAt: {
          type: "string",
        },
        caseName: {
          type: "string",
        },
        caseLabel: {
          type: "string",
        },
        caseSituation: {
          type: "string",
        },
        caseStatus: {
          type: "string",
        },
        chronorapso: {
          type: "string",
          maxLength: 100,
        },
        description: {
          type: "string",
        },
        contractingAgency: {
          type: "string",
        },
        createdByOldRapso: {
          type: "boolean",
        },
        dmsStructuration: {
          type: "string",
        },
        emittedDocs: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        isFavorite: {
          type: "boolean",
        },
        isProjectInBim: {
          type: "boolean",
        },
        city: {
          type: "string",
        },
        projectName: {
          type: "string",
        },
        userProfileId: {
          type: "string",
        },
        caseId: {
          type: "string",
        },
        webmailLogin: {
          type: "string",
        },
        webmailEmail: {
          type: "string",
        },
        webmailPrimaryId: {
          type: "string",
        },
        webmailPassword: {
          type: "string",
        },
        caseUuid: {
          type: "string",
        },
        projectEmail: {
          type: "string",
        },
        managerEmail: {
          type: "string",
        },
        managerUsermanagementUuid: {
          type: "string",
        },
        address: {
          type: "string",
        },
        postcode: {
          type: "string",
        },
        caseNumber: {
          type: "string",
        },
        receptionDate: {
          type: "string",
        },
        isNationalContract: {
          type: "boolean",
        },
        workDescription: {
          type: "string",
        },
        phase: {
          type: "string",
        },
        initialAmount: {
          type: "number",
        },
        estimatedDuration: {
          type: "number",
        },
        signDate: {
          type: "string",
        },
        missionAmount: {
          type: "string",
        },
        mission: {
          type: "string",
        },
        missionLabel: {
          type: "string",
        },
        agencyCode: {
          type: "string",
        },
        agencyName: {
          type: "string",
        },
        photoDocumentId: {
          type: "string",
        },
        opeStartDate: {
          type: "string",
        },
        client: {
          type: "string",
        },
        substituteManagerEmail: {
          type: "string",
        },
        substituteManagerUsermanagementUuid: {
          type: "string",
        },
        otherMissions: {
          type: "string",
        },
        contactClientMail: {
          type: "string",
        },
        contactClientUsermanagementUuid: {
          type: "string",
        },
        typology: {
          type: "string",
        },
        comment: {
          type: "string",
        },
        oldProjectId: {
          type: "string",
        },
        lastReportTemplateName: {
          type: "string",
        },
        lastReportDate: {
          type: "string",
        },
        alertCodesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        projectConfig: {
          type: "string",
        },
        projectPilot: {
          type: "string",
        },
        managerFirstName: {
          type: "string",
        },
        managerLastName: {
          type: "string",
        },
        perimeter: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  project_config: {
    schema: {
      title: "projectConfig schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        analyticalAxisUuid: {
          type: "array",
          items: {
            type: "string",
          },
        },
        project: {
          type: "string",
        },
        submissions: {
          type: "array",
          items: {
            type: "string",
          },
        },
        priorityGroup: {
          type: "string",
        },
        ventilationGroup: {
          type: "string",
        },
        differabilityGroup: {
          type: "string",
        },
        rOIGroup: {
          type: "string",
        },
        sourcePricingGroup: {
          type: "string",
        },
        costScaleGroup: {
          type: "string",
        },
        projectionGroup: {
          type: "string",
        },
        isConfidential: {
          type: "boolean",
        },
        missionContext: {
          type: "string",
        },
        startDate: {
          type: "string",
        },
        duration: {
          type: "number",
        },
        hasDisposition: {
          type: "boolean",
        },
        hasQuotation: {
          type: "boolean",
        },
        hasRisk: {
          type: "boolean",
        },
        hasPhoto: {
          type: "boolean",
        },
        hasAction: {
          type: "boolean",
        },
        hasPriority: {
          type: "boolean",
        },
        hasAmount: {
          type: "boolean",
        },
        hasROI: {
          type: "boolean",
        },
        hasVentilation: {
          type: "boolean",
        },
        hasDifferability: {
          type: "boolean",
        },
        hasYear: {
          type: "boolean",
        },
        hasSourcePricing: {
          type: "boolean",
        },
        hasCostScale: {
          type: "boolean",
        },
        hasProjection: {
          type: "boolean",
        },
        siteCharacteristics: {
          type: "object",
        },
        buildingCharacteristics: {
          type: "object",
        },
        infrastructureCharacteristics: {
          type: "object",
        },
        storeyCharacteristics: {
          type: "object",
        },
        zoneCharacteristics: {
          type: "object",
        },
        spaceCharacteristics: {
          type: "object",
        },
        componentCharacteristics: {
          type: "object",
        },
        referenceText: {
          type: "string",
        },
        reportConfigGraphicsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        reportTemplateFileType: {
          type: "string",
        },
        reportClientLogo: {
          type: "string",
        },
        reportLanguage: {
          type: "string",
        },
        reportTitle: {
          type: "string",
        },
        hasRedFlagSynthesis: {
          type: "boolean",
        },
        hasGrouping: {
          type: "boolean",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  project_config_analytical_axis: {
    schema: {
      title: "projectConfigAnalyticalAxes schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        isDefaultAnalyticalAxisEditable: {
          type: "string",
        },
        isOthersAnalyticalAxesEditable: {
          type: "string",
        },
        isDefaultQuotationGroupEditable: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
        projectConfig: {
          type: "string",
        },
        quotationGroup: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  rapsotec_node_amos: {
    schema: {
      title: "rapsotecNodeAmos schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        path: {
          type: "string",
        },
        level: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        parentNode: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        expertComment: {
          type: "string",
        },
        missionCodesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        destinationCodesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        typeCodesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        isArchived: {
          type: "boolean",
        },
        annexe: {
          type: "boolean",
        },
        modifiedByUuid: {
          type: "string",
        },
        modifiedByUser: {
          type: "string",
        },
        conditionalTitles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        conditionalDisplay: {
          type: "array",
          items: {
            type: "object",
          },
        },
        updatedAt: {
          type: "string",
        },
        regulationDocumentsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.missionCodes;
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  site_asset: {
    schema: {
      title: "siteAssets schema",
      version: 5,
      primaryKey: "uuid",
      type: "object",
      properties: {
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        actors: {
          type: "array",
          items: {
            type: "object",
          },
        },
        tags: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        delete oldDoc.isCaseAsset;
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  space_asset: {
    schema: {
      title: "spaceAssets schema",
      version: 6,
      primaryKey: "uuid",
      type: "object",
      properties: {
        building: {
          type: "string",
        },
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        actors: {
          type: "array",
          items: {
            type: "object",
          },
        },
        code: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  storey_asset: {
    schema: {
      title: "storeyAssets schema",
      version: 8,
      primaryKey: "uuid",
      type: "object",
      properties: {
        building: {
          type: "string",
        },
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        actors: {
          type: "array",
          items: {
            type: "object",
          },
        },
        surface: {
          type: "array",
          items: {
            type: "object",
          },
        },
        nbStoreySanitary: {
          type: "number",
        },
        nbStoreySanitarySource: {
          type: "string",
        },
        nbStoreyWomenSanitary: {
          type: "number",
        },
        nbStoreyWomenSanitarySource: {
          type: "string",
        },
        nbStoreyMenSanitary: {
          type: "number",
        },
        nbStoreyMenSanitarySource: {
          type: "string",
        },
        maximumWorkforceLinkedToSanitaryNumber: {
          type: "number",
        },
        maximumWorkforceLinkedToSanitaryNumberSource: {
          type: "string",
        },
        nbStoreyEmergencyExits: {
          type: "number",
        },
        nbStoreyEmergencyExitsSource: {
          type: "string",
        },
        nbStoreyPassageUnits: {
          type: "number",
        },
        nbStoreyPassageUnitsSource: {
          type: "string",
        },
        cumulativeWidthOfPassage: {
          type: "number",
        },
        cumulativeWidthOfPassageSource: {
          type: "string",
        },
        maximumWorkforceRelatedToEmergencyExits: {
          type: "number",
        },
        maximumWorkforceRelatedToEmergencyExitsSource: {
          type: "string",
        },
        ventilationFreshAirFlows: {
          type: "number",
        },
        ventilationFreshAirFlowsSource: {
          type: "string",
        },
        storeyVolume: {
          type: "number",
        },
        storeyVolumeSource: {
          type: "string",
        },
        openingsSurface: {
          type: "number",
        },
        openingsSurfaceSource: {
          type: "string",
        },
        maximumWorkforceRelatedToVentilation: {
          type: "number",
        },
        maximumWorkforceRelatedToVentilationSource: {
          type: "string",
        },
        averageOccupancyRatio: {
          type: "number",
        },
        averageOccupancyRatioSource: {
          type: "string",
        },
        bearingCapacityOfTheFloor: {
          type: "number",
        },
        bearingCapacityOftheSlab: {
          type: "number",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        return oldDoc;
      },
      5: (oldDoc) => {
        delete oldDoc.bearingCapacityOfTheSlab;
        return oldDoc;
      },
      6: (oldDoc) => {
        delete oldDoc.bearingCapacityOfTheFloorSource;
        delete oldDoc.bearingCapacityOftheSlabSource;
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
      8: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  sub_mission: {
    schema: {
      title: "subMissions schema",
      version: 4,
      primaryKey: "code",
      type: "object",
      properties: {
        code: {
          type: "string",
          maxLength: 100,
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        mission: {
          type: "string",
        },
        defaultExtraConfig: {
          type: "object",
        },
        editableExtraConfigRule: {
          type: "object",
        },
        reportTemplateTypesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        componentTypesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        rduCode: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.reportTemplateType;
        return oldDoc;
      },
      3: (oldDoc) => {
        delete oldDoc.componentTypes;
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  typology_type_node: {
    schema: {
      title: "typologyTypeNodes schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        path: {
          type: "string",
        },
        level: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        readablePath: {
          type: "string",
        },
        modificationOf: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        updatedAt: {
          type: "string",
        },
        modifiedByUuid: {
          type: "string",
        },
        conditionalTitles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        conditionalDisplay: {
          type: "array",
          items: {
            type: "object",
          },
        },
        displayedCountriesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        children: {
          type: "array",
          items: {
            type: "string",
          },
        },
        parentNode: {
          type: "string",
        },
        objectId: {
          type: "string",
        },
        manuallyCreated: {
          type: "boolean",
        },
        isDisplay: {
          type: "boolean",
        },
        isOpen: {
          type: "boolean",
        },
        isSelected: {
          type: "boolean",
        },
        referencialName: {
          type: "string",
        },
        rootNodePath: {
          type: "string",
        },
        fullLabel: {
          type: "string",
        },
        breadcrumbs: {
          type: "array",
          items: {
            type: "string",
          },
        },
        originalPath: {
          type: "string",
        },
        typologyType: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
        regulatory: {
          type: "string",
        },
        applicationCondition: {
          type: "string",
        },
        apcriClassification: {
          type: "string",
        },
        surveyRule: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.documentReviewsCount;
        delete oldDoc.smallestQuotationValue;
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  user: {
    schema: {
      title: "users schema",
      version: 4,
      primaryKey: "email",
      type: "object",
      properties: {
        uuid: {
          type: "string",
        },
        agency: {
          type: "string",
        },
        cloudId: {
          type: "string",
        },
        email: {
          type: "string",
          maxLength: 100,
        },
        firstName: {
          type: "string",
        },
        groups: {
          type: "array",
          items: {
            type: "string",
          },
        },
        isSuperUser: {
          type: "boolean",
        },
        isCurrentUser: {
          type: "boolean",
        },
        jobTitle: {
          type: "string",
        },
        lastName: {
          type: "string",
        },
        signature: {
          type: "string",
        },
        telephoneNumber: {
          type: "string",
        },
        lastLogin: {
          type: "string",
        },
        socotecCode: {
          type: "string",
        },
        usermanagementUuid: {
          type: "string",
        },
        managedProjects: {
          type: "array",
          items: {
            type: "string",
          },
        },
        roles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        language: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  user_perimeter: {
    schema: {
      title: "userPerimeter schema",
      version: 7,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        projectId: {
          type: "string",
          maxLength: 200,
        },
        userId: {
          type: "string",
        },
        aosItemUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
        aosObservableUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
        assets: {
          type: "object",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        data: {
          type: "object",
        },
        isArchived: {
          type: "boolean",
        },
        observationUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
        bimdataTokenMappingOffline: {
          type: "object",
        },
        assetUuidCollection: {
          type: "object",
        },
        aosItemUuidCollection: {
          type: "object",
        },
        offlineDocumentUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
        observationStatementUuid: {
          type: "array",
          items: {
            type: "string",
          },
        },
        offlineTagsUuids: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
      indexes: ["projectId"],
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.isArchived;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  zone_asset: {
    schema: {
      title: "zoneAssets schema",
      version: 6,
      primaryKey: "uuid",
      type: "object",
      properties: {
        building: {
          type: "string",
        },
        aosItem: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        path: {
          type: "string",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        actors: {
          type: "array",
          items: {
            type: "object",
          },
        },
        surface: {
          type: "array",
          items: {
            type: "object",
          },
        },
        code: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        delete oldDoc.isProjectAsset;
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  document_type: {
    schema: {
      title: "documentType schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        name: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  document_image_type: {
    schema: {
      title: "documentImageTypes schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        category: {
          type: "string",
        },
        label: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  observation_statement: {
    schema: {
      title: "observationStatements schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        justification: {
          type: "string",
        },
        statementType: {
          type: "string",
        },
        quotationValue: {
          type: "string",
        },
        createdByFirstname: {
          type: "string",
        },
        modifiedByFirstname: {
          type: "string",
        },
        createdByLastname: {
          type: "string",
        },
        modifiedByLastname: {
          type: "string",
        },
        observation: {
          type: "string",
          maxLength: 200,
        },
        objectTypeStatement: {
          type: "string",
        },
        documents: {
          type: "array",
          items: {
            type: "string",
          },
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
      indexes: ["observation"],
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.observation =
          oldDoc.observation === undefined
            ? oldDoc.observation
            : oldDoc.observation.toString();
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.isDraft;
        return oldDoc;
      },
    },
  },
  model_version: {
    schema: {
      title: "modelVersions schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        model: {
          type: "string",
        },
        version: {
          type: "number",
        },
        fetchedAt: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  periodicity: {
    schema: {
      title: "periodicities schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        start: {
          type: "string",
        },
        number: {
          type: "number",
        },
        durationType: {
          type: "string",
        },
        ending: {
          type: "string",
        },
        startDateFormat: {
          type: "string",
        },
        endingDateFormat: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.number =
          oldDoc.number === undefined
            ? oldDoc.number
            : parseFloat(oldDoc.number);
        return oldDoc;
      },
    },
  },
  object_type_statement: {
    schema: {
      title: "object_type_statement schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        contentType: {
          type: "string",
        },
        actionTypes: {
          type: "array",
          items: {
            type: "string",
          },
        },
        isApplicableToChildren: {
          type: "boolean",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        statementType: {
          type: "string",
        },
        quotationValue: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
        objectId: {
          type: "string",
        },
        analyticalAxisCode: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  action_type: {
    schema: {
      title: "actionTypes schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.uniqueCode =
          oldDoc.uniqueCode === undefined
            ? oldDoc.uniqueCode
            : parseFloat(oldDoc.uniqueCode);
        return oldDoc;
      },
      2: (oldDoc) => {
        oldDoc.uniqueCode =
          oldDoc.uniqueCode === undefined
            ? oldDoc.uniqueCode
            : oldDoc.uniqueCode.toString();
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  action_status: {
    schema: {
      title: "actionStatuses schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        shortLabel: {
          type: "string",
        },
        shortLabelFr: {
          type: "string",
        },
        shortLabelEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  analytical_axis: {
    schema: {
      title: "analyticalAxes schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        shortLabel: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        missionObject: {
          type: "string",
        },
        missionObjective: {
          type: "string",
        },
        referenceText: {
          type: "string",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        shortLabelFr: {
          type: "string",
        },
        shortLabelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        missionObjectFr: {
          type: "string",
        },
        missionObjectEnGb: {
          type: "string",
        },
        missionObjectiveFr: {
          type: "string",
        },
        missionObjectiveEnGb: {
          type: "string",
        },
        referenceTextFr: {
          type: "string",
        },
        referenceTextEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  cost_scale: {
    schema: {
      title: "costScales schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.uniqueCode =
          oldDoc.uniqueCode === undefined
            ? oldDoc.uniqueCode
            : oldDoc.uniqueCode.toString();
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  cost_scale_group: {
    schema: {
      title: "costScaleGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        costScales: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.costScalesList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  differability: {
    schema: {
      title: "differabilities schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  differability_group: {
    schema: {
      title: "differabilityGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        differabilities: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.differabilitiesList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  disposition_type: {
    schema: {
      title: "dispositionTypes schema",
      version: 9,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        referencialNodePath: {
          type: "string",
        },
        referencialNodeLabel: {
          type: "string",
        },
        referencialNodeUuid: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
        referencialNodeLevel: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        description: {
          type: "string",
        },
        referencialNodeLabelFr: {
          type: "string",
        },
        referencialNodeLabelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        regulatory: {
          type: "string",
        },
        applicationCondition: {
          type: "string",
        },
        surveyRule: {
          type: "string",
        },
        referencialNodeOriginalPath: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
      8: (oldDoc) => {
        return oldDoc;
      },
      9: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  functional_requirement: {
    schema: {
      title: "functionalRequirements schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  glossary: {
    schema: {
      title: "glossaries schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  priority: {
    schema: {
      title: "priorities schema",
      version: 5,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        value: {
          type: ["string", "number", "object", "array", "boolean"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.value =
          oldDoc.value === undefined ? oldDoc.value : oldDoc.value.toString();
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        oldDoc.value =
          oldDoc.value === undefined ? oldDoc.value : parseFloat(oldDoc.value);
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  priority_group: {
    schema: {
      title: "priorityGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        reportLabel: {
          type: "string",
        },
        priorities: {
          type: "array",
          items: {
            type: "string",
          },
        },
        reportLabelFr: {
          type: "string",
        },
        reportLabelEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.prioritiesList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  projection: {
    schema: {
      title: "projections schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  projection_group: {
    schema: {
      title: "projectionGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        projections: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.projectionsList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  quotation: {
    schema: {
      title: "quotations schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        quotationValue: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  quotation_analytical_axis: {
    schema: {
      title: "quotationAnalyticalAxes schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        analyticalAxis: {
          type: "string",
        },
        quotation: {
          type: "string",
        },
        description: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  quotation_group: {
    schema: {
      title: "quotationGroups schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        quotationsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        groupTypesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  quotation_group_type: {
    schema: {
      title: "quotationGroupTypes schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  quotation_value: {
    schema: {
      title: "quotationValues schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        value: {
          type: ["string", "number", "object", "array", "boolean"],
        },
        isArchived: {
          type: "boolean",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  report_template_type: {
    schema: {
      title: "reportTemplateTypes schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  report_text: {
    schema: {
      title: "reportTexts schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        text: {
          type: "string",
        },
        templateCode: {
          type: "string",
        },
        section: {
          type: "string",
        },
        textFr: {
          type: "string",
        },
        textEnGb: {
          type: "string",
        },
        sectionFr: {
          type: "string",
        },
        sectionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  risk_assessment: {
    schema: {
      title: "riskAssessments schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        colorCode: {
          type: "string",
        },
        weightingCoefficient: {
          type: "string",
        },
        riskRating: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  risk_assessment_group: {
    schema: {
      title: "riskAssessmentGroups schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        riskAssessments: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  r_o_i_group: {
    schema: {
      title: "roiGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        rOIs: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.rOIsList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  r_o_i: {
    schema: {
      title: "rois schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  source_pricing: {
    schema: {
      title: "sourcePricings schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  source_pricing_group: {
    schema: {
      title: "sourcePricingGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        sourcePricings: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.sourcePricingsList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  statement_type: {
    schema: {
      title: "statementTypes schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        missions: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        delete oldDoc.missionsList;
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  survey_rule: {
    schema: {
      title: "surveyRules schema",
      version: 0,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
  },
  typology_type: {
    schema: {
      title: "typologyTypes schema",
      version: 7,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        referencialNodePath: {
          type: "string",
        },
        referencialNodeLabel: {
          type: "string",
        },
        referencialNodeUuid: {
          type: "string",
        },
        referencialVersion: {
          type: "string",
        },
        referencialNodeLevel: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        description: {
          type: "string",
        },
        referencialNodeLabelFr: {
          type: "string",
        },
        referencialNodeLabelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
        regulatory: {
          type: "string",
        },
        applicationCondition: {
          type: "string",
        },
        apcriClassification: {
          type: "string",
        },
        surveyRule: {
          type: "string",
        },
        apcriClassificationLabel: {
          type: "string",
        },
        surveyRuleLabel: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        referencialNodeOriginalPath: {
          type: "string",
        },
        analyticalAxis: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
      6: (oldDoc) => {
        return oldDoc;
      },
      7: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  ventilation: {
    schema: {
      title: "ventilations schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        descriptionFr: {
          type: "string",
        },
        descriptionEnGb: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  ventilation_group: {
    schema: {
      title: "ventilationGroups schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        displayed: {
          type: "boolean",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        isArchived: {
          type: "boolean",
        },
        labelFr: {
          type: "string",
        },
        labelEnGb: {
          type: "string",
        },
        ventilations: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.ventilationsList;
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_building: {
    schema: {
      title: "aosBuilding schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        type: {
          type: "string",
        },
        site: {
          type: "string",
        },
        locationsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        structureTypology: {
          type: "string",
        },
        nbrOfDecks: {
          type: "string",
        },
        nbrOfBays: {
          type: "string",
        },
        range: {
          type: "string",
        },
        airDraft: {
          type: "string",
        },
        waterDraft: {
          type: "string",
        },
        bias: {
          type: "string",
        },
        totalLength: {
          type: "string",
        },
        usefulLength: {
          type: "string",
        },
        totalWidth: {
          type: "string",
        },
        usefulWidth: {
          type: "string",
        },
        roadwayWidth: {
          type: "string",
        },
        sidewalkWidth: {
          type: "string",
        },
        railingHeight: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_component: {
    schema: {
      title: "aosComponents schema",
      version: 8,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        site: {
          type: "string",
        },
        building: {
          type: "string",
        },
        storey: {
          type: "string",
        },
        zone: {
          type: "string",
        },
        space: {
          type: "string",
        },
        componentType: {
          type: "string",
        },
        typology: {
          type: "string",
        },
        installationRenewalDate: {
          type: "string",
        },
        dateFormat: {
          type: "string",
        },
        quantity: {
          type: "string",
        },
        theoricalLifespan: {
          type: "string",
        },
        length: {
          type: "string",
        },
        width: {
          type: "string",
        },
        height: {
          type: "string",
        },
        surface: {
          type: "string",
          items: {
            type: "object",
          },
        },
        volume: {
          type: "string",
        },
        weight: {
          type: "string",
        },
        volumicMass: {
          type: "string",
        },
        isSynced: {
          type: "string",
        },
        oldOfflineUuid: {
          type: "string",
          maxLength: 200,
        },
      },
      required: [],
      definitions: {},
      indexes: ["oldOfflineUuid"],
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        delete oldDoc.propertiesList;
        oldDoc.uniqueCode =
          oldDoc.uniqueCode === undefined
            ? oldDoc.uniqueCode
            : parseFloat(oldDoc.uniqueCode);
        oldDoc.quantity =
          oldDoc.quantity === undefined
            ? oldDoc.quantity
            : oldDoc.quantity.toString();
        oldDoc.theoricalLifespan =
          oldDoc.theoricalLifespan === undefined
            ? oldDoc.theoricalLifespan
            : oldDoc.theoricalLifespan.toString();
        oldDoc.length =
          oldDoc.length === undefined
            ? oldDoc.length
            : oldDoc.length.toString();
        oldDoc.width =
          oldDoc.width === undefined ? oldDoc.width : oldDoc.width.toString();
        oldDoc.height =
          oldDoc.height === undefined
            ? oldDoc.height
            : oldDoc.height.toString();
        oldDoc.surface =
          oldDoc.surface === undefined
            ? oldDoc.surface
            : oldDoc.surface.toString();
        oldDoc.volume =
          oldDoc.volume === undefined
            ? oldDoc.volume
            : oldDoc.volume.toString();
        oldDoc.weight =
          oldDoc.weight === undefined
            ? oldDoc.weight
            : oldDoc.weight.toString();
        oldDoc.volumicMass =
          oldDoc.volumicMass === undefined
            ? oldDoc.volumicMass
            : oldDoc.volumicMass.toString();
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        oldDoc.oldOfflineUuid =
          oldDoc.oldOfflineUuid === undefined
            ? oldDoc.oldOfflineUuid
            : oldDoc.oldOfflineUuid.toString();
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        oldDoc.oldOfflineUuid =
          oldDoc.oldOfflineUuid === undefined
            ? oldDoc.oldOfflineUuid
            : oldDoc.oldOfflineUuid.toString();
        return oldDoc;
      },
      6: (oldDoc) => {
        oldDoc.oldOfflineUuid =
          oldDoc.oldOfflineUuid === undefined
            ? oldDoc.oldOfflineUuid
            : oldDoc.oldOfflineUuid.toString();
        return oldDoc;
      },
      7: (oldDoc) => {
        oldDoc.oldOfflineUuid =
          oldDoc.oldOfflineUuid === undefined
            ? oldDoc.oldOfflineUuid
            : oldDoc.oldOfflineUuid.toString();
        return oldDoc;
      },
      8: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_component_type: {
    schema: {
      title: "aosComponentTypes schema",
      version: 3,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        source: {
          type: "string",
        },
        referencialNodeLabel: {
          type: "string",
        },
        referencialNodeLevel: {
          type: "number",
        },
        referencialNodeUuid: {
          type: "string",
        },
        referencialNodePath: {
          type: "string",
        },
        referencialNodeOriginalPath: {
          type: "string",
        },
        children: {
          type: "array",
          items: {
            type: "string",
          },
        },
        isSelected: {
          type: "boolean",
        },
        allowedStructureCreation: {
          type: "array",
          items: {
            type: "string",
          },
        },
        typology: {
          type: "array",
          items: {
            type: "string",
          },
        },
        referencialVersion: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_component_type_property: {
    schema: {
      title: "aosComponentTypeProperties schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        componentType: {
          type: "string",
        },
        propertyDefinition: {
          type: "string",
        },
        valueChoices: {
          type: "array",
          items: {
            type: "string",
          },
        },
        defaultValue: {
          type: "object",
        },
        order: {
          type: "number",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        delete oldDoc.uniqueCode;
        return oldDoc;
      },
      4: (oldDoc) => {
        oldDoc.propertyDefinition =
          oldDoc.propertyDefinition === undefined
            ? oldDoc.propertyDefinition
            : oldDoc.propertyDefinition.toString();
        return oldDoc;
      },
    },
  },
  aos_typology: {
    schema: {
      title: "aosTypologies schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        structureType: {
          type: "string",
        },
        label: {
          type: "string",
        },
        ordering: {
          type: "number",
        },
        parentSource: {
          type: "string",
        },
        buildingTypology: {
          type: "object",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_property_definition: {
    schema: {
      title: "aosPropertyDefinitions schema",
      version: 5,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        name: {
          type: "string",
        },
        description: {
          type: "string",
        },
        source: {
          type: "string",
        },
        bimElements: {
          type: "array",
          items: {
            type: "string",
          },
        },
        unitType: {
          type: "string",
        },
        unitPrefix: {
          type: "string",
        },
        valueType: {
          type: "string",
        },
        valueChoices: {
          type: "array",
          items: {
            type: "string",
          },
        },
        uniqueCode: {
          type: ["string", "number"],
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        oldDoc.uniqueCode =
          oldDoc.uniqueCode === undefined
            ? oldDoc.uniqueCode
            : oldDoc.uniqueCode.toString();
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
      5: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_component_property: {
    schema: {
      title: "aosComponentProperties schema",
      version: 5,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        value: {
          type: ["string", "number", "object", "array", "boolean"],
        },
        propertyDefinition: {
          type: "string",
        },
        component: {
          type: "string",
          maxLength: 200,
        },
      },
      required: [],
      definitions: {},
      indexes: ["component"],
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        oldDoc.value =
          oldDoc.value === undefined ? oldDoc.value : parseFloat(oldDoc.value);
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        oldDoc.component =
          oldDoc.component === undefined
            ? oldDoc.component
            : oldDoc.component.toString();
        return oldDoc;
      },
      4: (oldDoc) => {
        oldDoc.component =
          oldDoc.component === undefined
            ? oldDoc.component
            : oldDoc.component.toString();
        return oldDoc;
      },
      5: (oldDoc) => {
        oldDoc.component =
          oldDoc.component === undefined
            ? oldDoc.component
            : oldDoc.component.toString();
        return oldDoc;
      },
    },
  },
  aos_location: {
    schema: {
      title: "aosLocations schema",
      version: 4,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        address: {
          type: "string",
        },
        geolocation: {
          type: "string",
        },
        manualStartGeolocation: {
          type: "string",
        },
        manualEndGeolocation: {
          type: "string",
        },
        parcelCodesList: {
          type: "array",
          items: {
            type: "object",
          },
        },
        postalCode: {
          type: "string",
        },
        countryName: {
          type: "string",
        },
        cityName: {
          type: "string",
        },
        locationType: {
          type: "string",
        },
        departmentName: {
          type: "string",
        },
        regionName: {
          type: "string",
        },
        isPrimaryLocation: {
          type: "boolean",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
      3: (oldDoc) => {
        return oldDoc;
      },
      4: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_site: {
    schema: {
      title: "aosSites schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        cloudId: {
          type: "number",
        },
        originId: {
          type: "number",
        },
        locationsList: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_space: {
    schema: {
      title: "aosSpaces schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        siteLabel: {
          type: "string",
        },
        buildingLabel: {
          type: "string",
        },
        building: {
          type: "string",
        },
        storey: {
          type: "string",
        },
        zone: {
          type: "string",
        },
        typology: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_storey: {
    schema: {
      title: "aosStoreys schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        longLabel: {
          type: "string",
        },
        storeyCodePrefix: {
          type: "number",
        },
        storeyCodeValue: {
          type: "number",
        },
        storeyCodeSuffix: {
          type: "number",
        },
        altimetryNgf: {
          type: "string",
        },
        altimetryReference: {
          type: "string",
        },
        tonnage: {
          type: "string",
        },
        building: {
          type: "string",
        },
        typology: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_zone: {
    schema: {
      title: "aosZones schema",
      version: 2,
      primaryKey: "uuid",
      type: "object",
      properties: {
        favoritePicture: {
          type: "string",
        },
        favoritePictureFileUrl: {
          type: "string",
        },
        favoritePictureFileName: {
          type: "string",
        },
        isDisplayed: {
          type: "boolean",
        },
        path: {
          type: "string",
        },
        uniqueCode: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        designation: {
          type: "string",
        },
        uuid: {
          type: "string",
          maxLength: 100,
        },
        updatedAt: {
          type: "string",
        },
        createdAt: {
          type: "string",
        },
        createdBy: {
          type: "string",
        },
        modifiedBy: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        createdOrUpdatedOffline: {
          type: "boolean",
        },
        siteLabel: {
          type: "string",
        },
        buildingLabel: {
          type: "string",
        },
        building: {
          type: "string",
        },
        storey: {
          type: "string",
        },
        typology: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
      2: (oldDoc) => {
        return oldDoc;
      },
    },
  },
  aos_component_node: {
    schema: {
      title: "aosComponentNodes schema",
      version: 1,
      primaryKey: "uuid",
      type: "object",
      properties: {
        uuid: {
          type: "string",
          maxLength: 100,
        },
        path: {
          type: "string",
        },
        level: {
          type: "number",
        },
        numchild: {
          type: "number",
        },
        readablePath: {
          type: "string",
        },
        modificationOf: {
          type: "string",
        },
        code: {
          type: ["string", "number"],
        },
        label: {
          type: "string",
        },
        description: {
          type: "string",
        },
        isArchived: {
          type: "boolean",
        },
        updatedAt: {
          type: "string",
        },
        modifiedByUuid: {
          type: "string",
        },
        conditionalTitles: {
          type: "array",
          items: {
            type: "object",
          },
        },
        conditionalDisplay: {
          type: "array",
          items: {
            type: "object",
          },
        },
        displayedCountriesList: {
          type: "array",
          items: {
            type: "string",
          },
        },
        children: {
          type: "array",
          items: {
            type: "string",
          },
        },
        parentNode: {
          type: "string",
        },
        objectId: {
          type: "string",
        },
        manuallyCreated: {
          type: "boolean",
        },
        isDisplay: {
          type: "boolean",
        },
        isOpen: {
          type: "boolean",
        },
        isSelected: {
          type: "boolean",
        },
        referencialName: {
          type: "string",
        },
        rootNodePath: {
          type: "string",
        },
      },
      required: [],
      definitions: {},
    },
    statics: {
      getFields: function () {
        return Object.keys(this.schema.jsonSchema.properties).filter(
          (field) => !field.startsWith("_")
        );
      },
    },
    migrationStrategies: {
      1: (oldDoc) => {
        return oldDoc;
      },
    },
  },
};

export { schemas };
