import { BaseAdminGroupModel } from "./baseModels";

export const QUOTATION_GROUP_TYPES = {
  DOC_REVIEW: "DOC_REVIEW",
  OBSERVATION: "OBSERVATION",
  CONSERVATION: "CONSERVATION",
  RUSTING: "RUSTING",
  FIRE_SAFETY: "FIRE_SAFETY",
  FUNCTIONAL_REQUIREMENTS: "FUNCTIONAL_REQUIREMENTS",
};

export default class QuotationGroupType extends BaseAdminGroupModel {
  static name = "QuotationGroupType";

  static entity = "quotationGroupTypes";

  static fields() {
    return {
      ...super.fields(),
    };
  }
}
