import { Model } from "@vuex-orm/core";
import { Document } from "@/models";

export default class AmosDocument extends Model {
  static name = "AmosDocument";
  static entity = "amosDocuments";

  static fields() {
    return {
      documentUuid: this.string(""),
      submission: this.string(""),
      documentData: this.belongsTo(Document, "documentUuid"),
    };
  }
}
